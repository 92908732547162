import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Layout from "../../component/Layout"
import { validateDate2 } from "../../helpers/DateUtilities";
import { ErrorMessage } from "@hookform/error-message";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {blockLessGreaterThanChar} from "../../helpers/ValidationUtilities";
import { useReportGeneration } from "./hooks/useReportGeneration";
import ReportDateRangePicker from "./components/ReportDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { reportLabels } from './components/ReportLabels';


export interface Props {}

const Reports: React.FC<Props> = props => {
  const {
    control,
    register,
    handleSubmit,
    errors,
    getValues,
    btnDisable,
    onSubmit
  } = useReportGeneration();

  const breadcrumb: any = [{ title: reportLabels.title }];
  
  return (
    <Layout breadcrumList={breadcrumb} >
      <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card className="mb-3">
              <Card.Header>
                  <Row>
                    <Col className="fs-5">
                      <strong className="mx-2 colorDark">{reportLabels.title}</strong>
                    </Col>  
                  </Row>
              </Card.Header>
              <Card.Body>
                  <Container>
                    <Row className="mb-3">
                      <Col sm={3} lg={2}>
                        <Form.Label>{reportLabels.reportTitle}</Form.Label> 
                      </Col>
                      <Col sm={8} lg={10}>
                        <Form.Control 
                          type="text" 
                          {...register("ReportTitle", {  
                            required: reportLabels.titleRequired,  
                          })}
                          onKeyDown={blockLessGreaterThanChar}
                          className="required"
                          placeholder={reportLabels.enterTitle} 
                        />
                        <ErrorMessage 
                          errors={errors} 
                          name="ReportTitle" 
                          render={({ message }) => <span className="error-message">{message}</span>}
                        />
                      </Col>
                    </Row> 
                    <ReportDateRangePicker
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      validateDate2={validateDate2}
                    />
                    <Row className="mb-3"> 
                      <Col className="textAlign-end">
                        <Button 
                          variant="primary" 
                          type="submit" 
                          disabled={btnDisable}
                        >
                          {reportLabels.generateReport}
                        </Button>
                      </Col>
                    </Row>
                  </Container>                  
              </Card.Body>
            </Card>
          </LocalizationProvider>
        </Form>  
      </Layout>
    )
}
export default Reports