import { DeliveryMethodEnum, DisclosureTypeEnum, DispositionTypeEnum, ReleaseStatusEnum, RequestTypeEnum, RequesterSourceTypeEnum } from "../helpers/Enums";
import ApplicantModel from "./ApplicantModel";
import { RequestActionModel } from "./RequestActionModel";
import { RequestExceptionModel } from "./RequestExceptionModel";


export default class RequestModel {
    id: number;
    fileNumber: string;
    dateReceived: Date;
    dueDate?:Date ; 
    applicant: ApplicantModel;
    applicantId:number;
    requestSummary: string;
    requestText: string;    
    requestTypeId: RequestTypeEnum|null;
    requestTypeName: string; 
    requesterSourceTypeId: RequesterSourceTypeEnum|null;
    requesterSourceName: string;
    sharepointUrl: string;
    requestStatus: string;
    numberOfPages: number| null;
    extensionRequired: number| null;
    completedDate: Date | null;
    disclosureTypeId: DisclosureTypeEnum|null;
    disclosureName: string;
    dispositionTypeId: DispositionTypeEnum|null;
    dispositionName: string;
    releaseStatusTypeId: ReleaseStatusEnum|null;
    releaseStatusName: string;
    deliveryMethodTypeId: DeliveryMethodEnum|null;
    deliveryMethodName: string;
    requestExceptions: RequestExceptionModel[];
    requestActions: RequestActionModel[] ; 
    constructor(){
        this.id=0;
        this.fileNumber='';
        this.dateReceived=new Date();
        this.dueDate =new Date();
        this.dueDate.setDate(this.dueDate.getDate() + 30); 
        this.applicant=new ApplicantModel(); 
        this.applicantId=0; 
        this.requestSummary='';
        this.requestText='';    
        this.requestTypeId=null;
        this.requestTypeName=''; 
        this.requesterSourceTypeId=null;
        this.requesterSourceName='';
        this.sharepointUrl='';
        this.requestStatus='';
        this.numberOfPages=null; 
        this.extensionRequired=null;
        this.completedDate=null;
        this.disclosureTypeId=null;
        this.disclosureName='';
        this.dispositionTypeId=null;
        this.dispositionName='';
        this.releaseStatusTypeId=null;
        this.releaseStatusName='';
        this.deliveryMethodTypeId=null;
        this.deliveryMethodName='';
        this.requestExceptions=[];
        this.requestActions =[];
    } 
}

