import {Form, Row, Col} from "react-bootstrap";
import {Controller} from "react-hook-form";
import {Checkbox} from "@mui/material";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import { ActionLabels } from "../../components/ActionLabels";

const ResponseReceivedEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditFormState: {control}
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.responseReceived}</Form.Label>
                <Controller
                    name="responseReceived"
                    control={control}
                    render={({field}) =>
                        <Checkbox
                            {...field}
                            inputRef={field.ref}
                            checked={!!field.value}
                        />}
                />
            </Form.Group>
        </Row>
    );
};

export default ResponseReceivedEdit;
