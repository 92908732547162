import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../../component/Layout";
import { useRequestsSummary } from './hooks/useRequestSummary';
import { ActiveRequestsSummary } from './components/ActiveRequestsSummary';
import { TotalRequestsSummary } from './components/TotalRequestsSummary';
import { ActiveRequestsDetails } from './components/ActiveRequestsDetails';

const HomePage: React.FC = () => {
    const { requestsSummary, loading } = useRequestsSummary();

    return (
        <Layout>
            <Row>
                <Col md={5} className="mb-4 mb-lg-0">
                    <ActiveRequestsSummary loading={loading} requestsSummary={requestsSummary} />
                </Col>
                <Col md={7}>
                    <TotalRequestsSummary loading={loading} requestsSummary={requestsSummary} />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <ActiveRequestsDetails loading={loading} requestsSummary={requestsSummary} />
                </Col>
            </Row>
            <br/>
        </Layout>
    );
};

export default HomePage;