import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RequestFormInput } from './RequestFormInput';
import { useRequestEditContext } from '../../../../contexts/RequestEditContext';
import { validateDate, calculateLifecycle } from '../../../../helpers/DateUtilities';
import { blockInvalidWholeNumberChar } from '../../../../helpers/ValidationUtilities';
import dayjs from 'dayjs';
import { RequestLabels } from '../../components/RequestLabels';

export const CompletedRequestEdit: React.FC = () => {
    const { 
        requestEditState: { dueDateMinDate, dispositionTypeId },
        requestEditStateActions: { setViewLifecycle },
        requestEditFormState: { register, control, formState, getValues }
    } = useRequestEditContext();

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        if (date) {
            const dateReceived = getValues('dateReceived');
            if (dateReceived) {
                const lifecycle = calculateLifecycle(dateReceived, date.toDate());
                setViewLifecycle(lifecycle);
            }
        }
    };

    const fieldValidation = {
        numberOfPages: {
            max: { 
                value: 999999, 
                message: "Number of pages must be less than 1000000." 
            },
            valueAsNumber: true
        }
    };    

    return (
        <Card className="mb-3">
            <Card.Header>
                <strong>{RequestLabels.headers.completedDetails}</strong>
            </Card.Header>
            <Card.Body>
                <RequestFormInput
                    label={RequestLabels.labels.numberOfPages}
                    name="numberOfPages"
                    register={register}
                    rules={fieldValidation.numberOfPages}
                    type="number"
                    onKeyDown={blockInvalidWholeNumberChar}
                    onPaste={blockInvalidWholeNumberChar}
                    formState={formState}
                />

                <div className="row mb-3">
                    <div className="col-sm-4">
                        <Form.Label>{RequestLabels.labels.completedDate}</Form.Label>
                    </div>
                    <div className="col-sm-8">
                        <Controller
                            name="completedDate"
                            control={control}
                            rules={{
                                    validate: (date) => validateDate(date, "Completed Date",dueDateMinDate,`The Completed Date cannot be earlier than the Date Received.`, dayjs(new Date()), 'The Completed Date cannot be a future date.'),
                                    required:Number(dispositionTypeId)>0  ? 'The Completed Date is required for Requests with Dispositions.' : false
                            }}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    value={value ? dayjs(value) : null}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        handleDateChange(newValue);
                                    }}
                                    minDate={dueDateMinDate}
                                    maxDate={dayjs()}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            className: "form-control",
                                            placeholder: RequestLabels.placeholders.completedDate
                                        }
                                    }}
                                />
                            )}
                        />
                        {formState.errors.completedDate && (
                            <span className="error-message">
                                {formState.errors.completedDate.message}
                            </span>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};