import './App.css';
import "./styles/css/site.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-widgets/styles.css";

import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

import HomePage from "./pages/Home/Home";
import FOIPRequestDetails from './pages/Request/Details/FOIPRequestDetails';
import ApplicantDetails from './pages/Applicant/Details/ApplicantDetails';
import SearchPage from './pages/Search/Search';
import AddNewApplicant from './pages/Applicant/Edit/ApplicantEdit';
import Login from './pages/Login';
import ActionDetails from './pages/Action/Details/ActionDetails';
import ActionEdit from './pages/Action/Edit/ActionEdit';
import FOIPRequestEdit from './pages/Request/Edit/FOIPRequestEdit';
import {pageRouteUrls} from './helpers/PageRouteUrls';
import Reports from './pages/Reports/Reports';
import {storageMng} from './helpers/StorageMng';
import MngExceptions from './pages/Exceptions/MngExceptions';
import NotificationsView from './pages/Notifications/View/NotificationsView';
import ScrollToTop from './pages/ScrollToTop';
import AppProviders from "./contexts/AppProviders";
import ConfigureExceptionsView from './pages/ConfigureExceptions/View/ConfigureExceptionsView';
import ConfigureExceptionsAdd from './pages/ConfigureExceptions/Add/ConfigureExceptionsAdd';
import NotificationsEdit from './pages/Notifications/Edit/NotificationsEdit';

function App() {
    const userInfo = storageMng.getUserProfile();

    return (
        <AppProviders>
            <>
                {/* ALL ROUTES WILL BE ACCESSIBLE ONLY WHEN USER IS AUTHENTICATED */}
                <AuthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            {userInfo?.isAdministrator || userInfo?.isVisitor ?
                                (
                                    <>
                                        <Route path={pageRouteUrls.Home()} element={<HomePage/>}/>
                                        <Route path='home' element={<HomePage/>}/>

                                        <Route path={pageRouteUrls.Request_Details(':id')}
                                               element={<FOIPRequestDetails/>}/>

                                        <Route path={pageRouteUrls.Applicant_Details(":id")}
                                               element={<ApplicantDetails/>}/>

                                        <Route path={pageRouteUrls.Action_Details(':requestId', ':id')}
                                               element={<ActionDetails/>}/>

                                        <Route path={pageRouteUrls.Search()} element={<SearchPage/>}/>
                                    </>
                                ) : (<></>)
                            }
                            {userInfo?.isAdministrator ?
                                (
                                    <>
                                        <Route path={pageRouteUrls.Request_Add()} element={<FOIPRequestEdit/>}/>
                                        <Route path={pageRouteUrls.Request_Edit(':id')} element={<FOIPRequestEdit/>}/>

                                        <Route path={pageRouteUrls.Applicant_Add()} element={<AddNewApplicant/>}/>
                                        <Route path={pageRouteUrls.Applicant_Edit(":id")} element={<AddNewApplicant/>}/>

                                        <Route path={pageRouteUrls.Action_Add(':requestId')} element={<ActionEdit/>}/>
                                        <Route path={pageRouteUrls.Action_Edit(':requestId', ':id')}
                                               element={<ActionEdit/>}/>

                                        <Route path={pageRouteUrls.MngExceptions(':requestId')}
                                               element={<MngExceptions/>}/>
                                        <Route path={pageRouteUrls.Reports()} element={<Reports/>}/>

                                        <Route path={pageRouteUrls.NotificationsConfig_View()}
                                               element={<NotificationsView/>}/>
                                        <Route path={pageRouteUrls.NotificationsConfig_Edit()}
                                               element={<NotificationsEdit/>}/>

                                        <Route path={pageRouteUrls.ConfigureExceptions_View()}
                                               element={<ConfigureExceptionsView/>}/>  
                                        <Route path={pageRouteUrls.ConfigureExceptions_Add()}
                                               element={<ConfigureExceptionsAdd/>}/>  
                                               
                                    </>
                                ) : (<></>)
                            }

                            <Route path='*' element={<HomePage/>}/>

                            <Route path='signin-oidc' element={<HomePage/>}/>
                        </Routes>
                        <ScrollToTop/>
                    </BrowserRouter>
                </AuthenticatedTemplate>
                {/* ONLY LOGIN PAGE WILL BE VISIBLE FOR THE UNAUTHENTICATED USER AND THAT WOULD ALSO BE THE DEFAULT PAGE */}
                <UnauthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="*" element={<Navigate to="/" replace={true}/>}/>
                        </Routes>
                    </BrowserRouter>
                </UnauthenticatedTemplate>
            </>
        </AppProviders>
    );
}

export default App;
 
