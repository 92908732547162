export const validateConditionalRequired = (obj: any, conditionExists: boolean|undefined, message: string) => {
  if(!conditionExists) return false;

  return obj == null ? message : true;
};

export const blockInvalidNumberChar = (e: React.KeyboardEvent | React.ClipboardEvent) => {
    if (e.type === 'paste') {
        const pasteEvent = e as React.ClipboardEvent;
        const pastedData = pasteEvent.clipboardData.getData('text');
        if (!/^-?\d*\.?\d*$/.test(pastedData)) {
            e.preventDefault();
        }
    } else {
        const keyEvent = e as React.KeyboardEvent;
        ['e', 'E', '+', '-'].includes(keyEvent.key) && keyEvent.preventDefault();
    }
};

export const blockInvalidWholeNumberChar = (e: React.KeyboardEvent | React.ClipboardEvent) => {
    if (e.type === 'paste') {
        const pasteEvent = e as React.ClipboardEvent;
        const pastedData = pasteEvent.clipboardData.getData('text');
        if (!/^\d*$/.test(pastedData)) {
            e.preventDefault();
        }
    } else {
        const keyEvent = e as React.KeyboardEvent;
        ['e', 'E', '+', '-', '.', ','].includes(keyEvent.key) && keyEvent.preventDefault();
    }
};

export const blockLessGreaterThanChar = (e :any) => ['<', '>'].includes(e.key) && e.preventDefault();

export const namesValidationExp= /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s@.]+$/;

export const addressValidationExp=/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s\#\@\%\&\/@.]+$/;