import {createContext, useContext} from "react";
import {NotificationEditType} from "../types/NotificationEditType";

const NotificationEditContext = createContext<NotificationEditType | undefined>(undefined);
export default NotificationEditContext;

export const useNotificationEditContext = () => {
    const notificationEditContext = useContext(NotificationEditContext);
    if (!notificationEditContext) {
        throw new Error('useNotificationEditContext must be used within a NotificationEditContextProvider');
    }
    return notificationEditContext;
};


