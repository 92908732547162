import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ExceptionTypeModel from '../../../../models/ExceptionTypeModel';
import { useExceptionService } from '../../../../contexts/ExceptionContext';
import { pageRouteUrls } from '../../../../helpers/PageRouteUrls';
import { ConfigureExceptionsLabels } from "../../components/ConfigureExceptionsLabels";

function useExceptionAdd() {
    const [loading, setLoading] = useState<boolean>(false);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string|null>(null);
    
    const exceptionAddFormState = useForm<ExceptionTypeModel>({
        shouldUnregister: false,
        defaultValues: {}
    });

    const { reset } = exceptionAddFormState;
    
    const navigate = useNavigate();
    const exceptionService = useExceptionService();

    const onSubmit: SubmitHandler<ExceptionTypeModel> = async (data) => {
        setBtnDisable(true);   
        setLoading(true);  
        setErrMsg(null);
        
        const isExists = await exceptionService.ExistsByCode(data.code);

        if (isExists) {
            setErrMsg(ConfigureExceptionsLabels.messages.duplicateCodeError);
        } else {
            const success = await exceptionService.AddExceptionType(data);
            if (success) {    
                navigate(pageRouteUrls.ConfigureExceptions_View());
            } 
            reset(data);
        }
        
        setLoading(false);
        setBtnDisable(false);
    };

    const exceptionAddState = {
        loading,
        btnDisable,
        errMsg
    };

    const exceptionAddStateActions = {
        setLoading,
        setBtnDisable,
        setErrMsg
    };

    const exceptionAddActions = {
        navigate,
        onSubmit
    };

    return {
        exceptionAddState,
        exceptionAddStateActions,
        exceptionAddFormState,
        exceptionAddActions
    };
};

export default useExceptionAdd;