import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import ApplicantModel from "../../../../models/ApplicantModel";
import { formatDateAs } from "../../../../helpers/DateUtilities";
import { DateFormat } from "../../../../helpers/Constants";
import { ApplicantLabels } from "../../components/ApplicantLabels";

interface ApplicantInfoDetailsProps {
    applicant: ApplicantModel;
}

const ApplicantInfoDetails: React.FC<ApplicantInfoDetailsProps> = ({ applicant }) => (
    <>
        <Form.Group as={Col} controlId="formfirstName">
            <Form.Label>{ApplicantLabels.labels.firstName}</Form.Label>    
            <Form.Control plaintext readOnly value={applicant.firstName} />
        </Form.Group>
        <Form.Group as={Col} controlId="formlastName">
            <Form.Label>{ApplicantLabels.labels.lastName}</Form.Label>
            <Form.Control plaintext readOnly value={applicant.lastName} />
        </Form.Group>
        <Form.Group as={Col} controlId="formdateOfBirth">
            <Form.Label>{ApplicantLabels.labels.dateOfBirth}</Form.Label>
            <Form.Control plaintext readOnly value={formatDateAs(applicant.dateOfBirth, DateFormat)} />
        </Form.Group>
    </>
);

export default ApplicantInfoDetails;