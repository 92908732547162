import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Layout from "../../../component/Layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { NotificationConfigEdit } from "./components/NotificationConfigEdit";
import { Link } from "react-router-dom";
import { pageRouteUrls } from "../../../helpers/PageRouteUrls";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BreadcrumbGenerator } from "../../../helpers/BreadcrumbUtilities";
import { useNotificationsEdit } from "./hooks/useNotificationsEdit";
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import NotificationEditContext from "../../../contexts/NotificationEditContext";
import { NotificationsLabels } from "../components/NotificationsLabels";

export interface Props {}

const NotificationsEdit: React.FC<Props> = () => {
    const notificationEditContext = useNotificationsEdit();
    const {
        notificationEditState: { loading, btnDisable },
        notificationEditFormState: { handleSubmit },
        notificationEditActions: { onSubmit }
    } = notificationEditContext;

    const breadcrumb = BreadcrumbGenerator.generateNotificationEditBreadcrumb();

    return (
        <Layout breadcrumList={breadcrumb}>
            <NotificationEditContext.Provider value={notificationEditContext}>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">{NotificationsLabels.page.editTitle}</strong>
                                    </Col>
                                    <Col className="textAlign-end">
                                        <Button variant="primary" type="submit" disabled={btnDisable}>{NotificationsLabels.buttons.save}</Button>
                                        <Link to={pageRouteUrls.NotificationsConfig_View()} className="btn btn-dark">{NotificationsLabels.buttons.cancel}</Link>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <NotificationConfigEdit />
                                )}
                            </Card.Body>
                        </Card>
                    </LocalizationProvider>
                </Form>
            </NotificationEditContext.Provider>
        </Layout>
    );
};

export default NotificationsEdit;