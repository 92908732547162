import {Form, Row, Col} from "react-bootstrap";
import { ActionLabels } from "../components/ActionLabels";

const SearchSentDetails = ({actionData}: any) => {
    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.departmentToSearch}</Form.Label>
                <span className="ps-4">{actionData.departmentToSearch} </span>
            </Form.Group>
        </Row>
    );
};

export default SearchSentDetails;
