import { useMemo } from 'react';
import { Link } from "react-router-dom";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { pageRouteUrls } from "../../../helpers/PageRouteUrls";
import { useSearchContext } from '../../../contexts/SearchContext';

export const useSearchColumns = () => {
  const { searchState: { rows }, searchFormState } = useSearchContext();

  const persistenceState = () => ({ 
    searchParams: searchFormState.getValues(),
    rows 
  });

  return useMemo<GridColDef[]>(() => [
    { 
      field: 'fileNumber', 
      headerName: 'File Number',
      headerClassName: 'gridHeadTr',
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Link 
            to={pageRouteUrls.Request_Details(params.id)}
            state={{
              previousPageUrls: [pageRouteUrls.Search()],
              quSearchWrapper: persistenceState()
            }}
          >
            {params.value}
          </Link>
        </strong>
      ),
      width: 150
    },
    { 
      field: 'applicantFirstName',
      headerClassName: 'gridHeadTr', 
      headerName: 'Applicant', 
      valueGetter: (value, row) => `${row.applicant.firstName} ${row.applicant.lastName}`, 
      width: 260 
    },
    { 
      field: 'requestTypeName',
      headerClassName: 'gridHeadTr', 
      headerName: 'Request Type', 
      flex: 1 
    },
    { 
      field: 'dueDate',
      headerClassName: 'gridHeadTr', 
      headerName: 'Due Date',
      type: 'date',
      valueGetter: (value) => value && new Date(value),
      flex: 1 
    },
    { 
      field: 'dateReceived',
      headerClassName: 'gridHeadTr',
      headerName: 'Date Received',
      type: 'date',
      valueGetter: (value) => value && new Date(value),
      flex: 1 
    },
    { 
      field: 'completedDate',
      headerClassName: 'gridHeadTr',
      headerName: 'Closed Date',
      type: 'date',
      valueGetter: (value) => value && new Date(value),
      flex: 1 
    },
    { 
      field: 'dispositionName',
      headerClassName: 'gridHeadTr', 
      headerName: 'Disposition', 
      flex: 1.5 
    },
  ], [rows]); // Dependency on rows to ensure persistence state is current
};