import { useEffect, useState } from 'react';
import { NotificationConfigModel } from '../../../../models/NotificationConfigModel';
import { useNotificationConfigurationService } from '../../../../contexts/NotificationConfigurationContext';

export const useNotificationView = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [configData, setConfigData] = useState<NotificationConfigModel>(new NotificationConfigModel());
    const notificationConfigurationService = useNotificationConfigurationService();

    const fetchData = async () => {
        setLoading(true);
        const data = await notificationConfigurationService.GetConfig();
        setConfigData(data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {
        loading,
        configData,
        fetchData
    };
};