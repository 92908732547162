import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { useRequestEditContext } from "../../../../contexts/RequestEditContext";
import { pageRouteUrls } from "../../../../helpers/PageRouteUrls";
import { RequestProcessingEdit } from './RequestProcessingEdit';
import { RequestResponseEdit } from './RequestResponseEdit';
import { CompletedRequestEdit } from './CompletedRequestEdit';
import { RequestLabels } from "../../components/RequestLabels";

export const RequestEdit: React.FC = () => {
    const { 
        requestEditState: { id, btnDisable, previousPageUrls },
    } = useRequestEditContext();

    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">{RequestLabels.headers.requestDetails}</strong>
                    </Col>
                    <Col className="textAlign-end">
                        <Button variant="primary" type="submit" disabled={btnDisable}>
                            {RequestLabels.buttons.save}
                        </Button>
                        <Link 
                            to={id ? pageRouteUrls.Request_Details(id) : 
                                (previousPageUrls[0] === pageRouteUrls.Search() ? 
                                    pageRouteUrls.Search() : pageRouteUrls.Home())}
                            state={{ previousPageUrls }} 
                            className="btn btn-dark"
                        >
                            {RequestLabels.buttons.cancel}
                        </Link>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col>
                        <RequestProcessingEdit />
                    </Col>
                    <Col>
                        <RequestResponseEdit />
                        <CompletedRequestEdit />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};