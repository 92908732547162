import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { NotificationConfigModel } from '../../../../models/NotificationConfigModel';
import { pageRouteUrls } from '../../../../helpers/PageRouteUrls';
import { useNotificationConfigurationService } from '../../../../contexts/NotificationConfigurationContext';

export const useNotificationsEdit = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [configData, setConfigData] = useState<NotificationConfigModel>(new NotificationConfigModel());
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const navigate = useNavigate();

    const notificationEditFormState = useForm<NotificationConfigModel>({
        shouldUnregister: false,    
        defaultValues: {}
    });
    
    const { reset } = notificationEditFormState;
    
    const notificationConfigurationService = useNotificationConfigurationService();

    const fetchData = async () => {
        setBtnDisable(true);
        setLoading(true);
        
        const data = await notificationConfigurationService.GetConfig();
        setConfigData(data);
        reset(data);

        setLoading(false);
        setBtnDisable(false);
    }

    const onSubmit: SubmitHandler<NotificationConfigModel> = async (data) => {
        setBtnDisable(true);
        setLoading(true);

        const responseSave = await notificationConfigurationService.Save(data);
        
        reset(data);
        if (responseSave) {
            setConfigData(responseSave);
            navigate(pageRouteUrls.NotificationsConfig_View());
        }
        setLoading(false);
        setBtnDisable(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const notificationEditState = {
        loading,
        btnDisable,
        configData,
    };

    const notificationEditStateActions = {
        setLoading,
        setBtnDisable,
        setConfigData
    };

    const notificationEditActions = {
        fetchData,
        onSubmit
    };

    return {
        notificationEditState,
        notificationEditStateActions,
        notificationEditFormState,
        notificationEditActions
    };
}