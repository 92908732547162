import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaPlus } from 'react-icons/fa';
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import DashboardCard from '../../../component/dashboard/DashboardCard';
import { dashboardCardProperties } from '../hooks/dashboardCardProperties';
import { pageRouteUrls } from "../../../helpers/PageRouteUrls";
import { storageMng } from "../../../helpers/StorageMng";
import { DashboardIconEnum } from "../../../helpers/Enums";
import RequestsSummaryModel from "../../../models/RequestsSummaryModel";
import { Link } from 'react-router-dom';

interface Props {
    loading: boolean;
    requestsSummary: RequestsSummaryModel;
}

export const TotalRequestsSummary: React.FC<Props> = ({ loading, requestsSummary }) => {
    const userInfo = storageMng.getUserProfile();

    return (
        <Card className="same-height">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">Total Requests Summary</strong>
                    </Col>
                    <Col className="textAlign-end">
                        {userInfo?.isAdministrator ? (
                            <Link to={pageRouteUrls.Request_Add()} className="btn btn-primary">
                                <FaPlus className={'pb-1'}/> Add Request
                            </Link>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container>
                    {loading ? (
                        <LoadingSpinner/>
                    ) : (
                        <>
                            <Row>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.Total}
                                        backgroundColor={dashboardCardProperties.totalRequest.fill}
                                        itemName={dashboardCardProperties.totalRequest.title}
                                        itemsCount={requestsSummary.totalCount}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.Overdue}
                                        backgroundColor={dashboardCardProperties.overdueRequest.fill}
                                        itemName={dashboardCardProperties.overdueRequest.title}
                                        itemsCount={requestsSummary.overdueCount}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.Open}
                                        backgroundColor={dashboardCardProperties.openRequest.fill}
                                        itemName={dashboardCardProperties.openRequest.title}
                                        itemsCount={requestsSummary.openCount}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.Completed}
                                        backgroundColor={dashboardCardProperties.completedRequest.fill}
                                        itemName={dashboardCardProperties.completedRequest.title}
                                        itemsCount={requestsSummary.completedCount}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.CarriedForward}
                                        backgroundColor={dashboardCardProperties.carriedForwardRequest.fill}
                                        itemName={dashboardCardProperties.carriedForwardRequest.title}
                                        itemsCount={requestsSummary.carriedForwardCount}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <DashboardCard 
                                        iconType={DashboardIconEnum.Abandoned}
                                        backgroundColor={dashboardCardProperties.abandonedRequest.fill}
                                        itemName={dashboardCardProperties.abandonedRequest.title}
                                        itemsCount={requestsSummary.abandonedCount}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </Card.Body>
        </Card>
    );
};