import { Row, Col, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from "dayjs";
import { calculateLifecycle, validateDate } from "../../../../helpers/DateUtilities";
import { useRequestEditContext } from "../../../../contexts/RequestEditContext";
import { RequestLabels } from "../../components/RequestLabels";
import { getDatePickerProps } from "../../../../helpers/DateUtilities";

export const RequestInfoEdit = () => {

    const { 
        requestEditState: { request, dueDateMinDate, isOnHold, viewLifecycle},
        requestEditStateActions: { setDueDateMinDate, setViewLifecycle},
        requestEditFormState: { control, formState, setValue, clearErrors, getValues, trigger},
    } = useRequestEditContext();

    const handleDateReceivedChange = (date: Dayjs | null, field: any) => {
        const currentDate = date?.isValid() ? date : dayjs();
        setDueDateMinDate(currentDate);
        setViewLifecycle(calculateLifecycle(currentDate.toDate(), getValues().completedDate));

        if (request && (!request.requestActions || request.requestActions.length === 0)) {
            if (date?.isValid()) {
                const dueDate = date.add(30, 'day').toDate();
                request.dueDate = dueDate;
                setValue("dueDate", dueDate);
            }
        }

        clearErrors();
        field.onChange(date);
    };

    return (
        <Row className="mb-3">
            <Form.Group as={Col} >
                <Form.Label>{RequestLabels.labels.fileNumber}</Form.Label>
                <Form.Control plaintext readOnly value={request?.fileNumber} />
            </Form.Group>

            <Form.Group as={Col} >
                <Form.Label>{RequestLabels.labels.dateReceived}</Form.Label>
                <Controller
                    control={control}
                    name='dateReceived'
                    rules={{
                        required: 'The Date Received is required.',
                        validate: (date) => validateDate(
                            date, 
                            RequestLabels.labels.dateReceived, 
                            undefined, 
                            undefined, 
                            dayjs(), 
                            RequestLabels.messages.dateReceivedFuture
                        )
                    }}
                    render={({ field }) => (
                        <DatePicker
                            {...getDatePickerProps(() => trigger(), 'dateReceived')}
                            autoFocus
                            maxDate={dayjs()}
                            onChange={(date) => handleDateReceivedChange(date, field)}
                            value={field.value ? dayjs(field.value) : null}
                            slotProps={{
                                textField: {
                                    placeholder: RequestLabels.placeholders.dateReceived,
                                    className: "form-control requiredDate"
                                }
                            }}
                        />
                    )}
                />
                <ErrorMessage 
                    errors={formState.errors} 
                    name="dateReceived" 
                    render={({ message }) => <span className="error-message">{message}</span>} 
                />
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>{RequestLabels.labels.dueDate}</Form.Label>
                {!isOnHold && (
                    <Controller
                        control={control}
                        name='dueDate'
                        rules={{
                            required: 'The Due Date is required.',
                            validate: (date) => validateDate(
                                date, 
                                RequestLabels.labels.dueDate, 
                                dueDateMinDate, 
                                RequestLabels.messages.dueDatePriorToDateReceived, 
                                undefined, 
                                undefined
                            )
                        }}
                        render={({ field }) => (
                            <DatePicker
                                {...getDatePickerProps(() => trigger(),'dueDate')}
                                value={field.value ? dayjs(field.value) : null}
                                onChange={(date) => field.onChange(date)}
                                slotProps={{
                                    textField: {
                                        placeholder: RequestLabels.placeholders.dueDate,
                                        className: "form-control requiredDate"
                                    }
                                }}
                            />
                        )}
                    />
                )}
                {!isOnHold && (
                    <ErrorMessage 
                        errors={formState.errors} 
                        name="dueDate" 
                        render={({ message }) => <span className="error-message">{message}</span>} 
                    />
                )}
            </Form.Group>

            <Form.Group as={Col} >
                <Form.Label>{RequestLabels.labels.requestLifecycle}</Form.Label>
                <Form.Control plaintext readOnly value={`${viewLifecycle>=0?viewLifecycle:'--'} ${RequestLabels.messages.daysSuffix}`} />

            </Form.Group>

            <Form.Group as={Col} >
                <Form.Label>{RequestLabels.labels.requestStatus}</Form.Label>
                <Form.Control plaintext readOnly value={request?.requestStatus} />
            </Form.Group>
        </Row>
    ); 
}      
