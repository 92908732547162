import { RequestTypeEnum, SearchCriteriaEnum } from "../helpers/Enums";


export default class SearchParamModel {
    searchType: SearchCriteriaEnum|undefined=undefined;
    
    byDateType:number=1;
    byDateFrom:Date|undefined=undefined;
    byDateTo:Date|undefined=undefined;

    byApplicantName:string|undefined="";

    byFileNumber:string|undefined="";

    byKeywordTxt:string|undefined="";

    byRequestTypeId:RequestTypeEnum|undefined=undefined;
}
