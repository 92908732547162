import {Col, Form} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import {RequestActionModel} from "../../../../models/RequestActionModel";
import {useApplicationContext} from "../../../../contexts/ApplicationContext";
import { ActionLabels } from "../../components/ActionLabels";

const ActionTypeEdit = () => {
    const actionEditContext = useActionEditContext();
    const {
        actionEditState: { id, requestId, actionData, allActionTypes, actionType},
        actionEditStateActions: { setActionData},
        actionEditFormState: {register, reset, setValue, formState: {errors}},
        actionEditActions: {setActionTypeById}
    } = actionEditContext!;

    const { setValidationMessage } = useApplicationContext();

    const onActionTypeChange = (selectedValue: string) => {
        reset(new RequestActionModel());
        setValue("requestId", Number(requestId));
        let requestAction = {
            ...actionData,
            ['requestActionTypeId']: Number(selectedValue)
        };
        setActionTypeById(Number(selectedValue));
        setActionData(requestAction);
        setValidationMessage(null);
    };

    return (
        <>
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.actionType}</Form.Label>
                <select {...register("requestActionTypeId", {required: ActionLabels.messages.actionTypeRequired})}
                        className="form-select required"
                        onChange={(e) => onActionTypeChange(e.target.value)}
                        disabled={!!id}
                        value={actionData?.requestActionTypeId}>
                    <option key="SearchCriteria-1" value="">- Select -</option>
                    {allActionTypes.map((key) =>
                        <option key={"SearchCriteria" + key.id} value={key.id}>{key.name}</option>
                    )}
                </select>
                <ErrorMessage errors={errors} name="requestActionTypeId"
                              render={({message}) => <span
                                  className="error-message">{message}</span>}/>
            </Form.Group>
            <Form.Group as={Col} lg="6">
                <div className={"p-3"}></div>
                <Form.Label className={"pt-1 font-italic fw-bolder text-secondary"}>
                    {
                        (actionData?.requestActionTypeId > 0)
                            ? actionType?.requestActionDescription
                            : ''
                    }
                </Form.Label>
            </Form.Group>
        </>
    );
};

export default ActionTypeEdit;