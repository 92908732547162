export const NotificationsLabels = {
    page: {
        editTitle: 'Edit Configure Notifications',
        viewTitle: 'Configure Notifications'
    },
    form: {
        emailToggle: 'Turn Email Notifications On/Off',
        recipientEmail: 'Recipient Email Addresses (separated by ;)',
        dueDateReminder: 'Due Date Reminder Lead Time (in days)'
    },
    placeholders: {
        email: 'Enter Email Address(s)',
        days: 'Enter Number of Days'
    },
    validation: {
        invalidEmail: 'Please enter a valid email address',
        emailTooLong: 'Email address is too long',
        daysRange: (min: number, max: number) => 
            `Please enter a value between ${min} - ${max} for the number of days.`
    },
    buttons: {
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit'
    }
};