import { FC } from 'react';
import { Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useSearchContext } from '../../../../contexts/SearchContext';
import { blockLessGreaterThanChar } from "../../../../helpers/ValidationUtilities";

const FileNumberSearch: FC = () => {
  const { searchFormState } = useSearchContext();

  return (
    <div className="row mb-3">
      <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
        <label><strong>Search By File Number</strong></label>
      </div>
      <div className="col-sm-6">
        <Form.Control
          type="text"
          {...searchFormState.register("byFileNumber", {
            pattern: {
              value: /^[A-Za-z0-9\-]+$/,
              message: 'Invalid File Number. Please enter a valid File number for the search.'
            },
            maxLength: { 
              value: 11, 
              message: "Invalid File Number. Please enter a valid File number for the search." 
            },
            required: 'File Number is required.',
          })}
          onKeyDown={blockLessGreaterThanChar}
          className="required"
          placeholder="Enter File Number"
        />
        <ErrorMessage
          errors={searchFormState.formState.errors}
          name="byFileNumber"
          render={({ message }) => <span className="error-message">{message}</span>}
        />
      </div>
    </div>
  );
};

export default FileNumberSearch;