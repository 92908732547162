export const reportLabels = {
    // General
    title: 'Reports',

    // Actions
    generateReport: 'Generate Report',
    
    // Filters
    reportTitle: 'Report Title',
    reportingPeriod: 'Reporting Period',
    fromDate: 'From',
    toDate: 'To',

    //Placeholders
    fromDatePlaceholder: 'Enter From Date',
    toDatePlaceholder: 'Enter To Date',
    
    // Error Messages
    invalidToDate: 'To date cannot be prior to the From date',
    invalidFromDate: 'From date cannot be prior to the To date',
    dateRangeRequired: 'Please enter both From and To Dates for the report',
    
    // Form Fields
    enterTitle: 'Enter the Report Title',
    titleRequired: 'Please enter the Report Title'
  };