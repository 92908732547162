import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import DashboardRequestCard from '../../../component/dashboard/DashboardRequestCard';
import { dashboardCardProperties } from '../hooks/dashboardCardProperties';
import RequestsSummaryModel from "../../../models/RequestsSummaryModel";

interface Props {
    loading: boolean;
    requestsSummary: RequestsSummaryModel;
}

export const ActiveRequestsDetails: React.FC<Props> = ({ loading, requestsSummary }) => {
    return (
        <Card className="same-height">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">Active Requests Details</strong>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col md={3}>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <DashboardRequestCard
                                    cardTitle={dashboardCardProperties.onTrackRequestDetail.title}
                                    fill={dashboardCardProperties.onTrackRequestDetail.fill}
                                    rowName="onTrackRow"
                                    requests={requestsSummary.onTrackRequests} />
                            )}
                        </Col>
                        <Col md={3}>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <DashboardRequestCard
                                    cardTitle={dashboardCardProperties.dueWeekRequestDetail.title}
                                    fill={dashboardCardProperties.dueWeekRequestDetail.fill}
                                    rowName="dueWeekkRow"
                                    requests={requestsSummary.dueWeekRequests} />
                            )}
                        </Col>
                        <Col md={3}>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <DashboardRequestCard
                                    cardTitle={dashboardCardProperties.overdueRequestDetail.title}
                                    fill={dashboardCardProperties.overdueRequestDetail.fill}
                                    rowName="overdueRow"
                                    requests={requestsSummary.overdueRequests} />
                            )}
                        </Col>
                        <Col md={3}>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <DashboardRequestCard
                                    cardTitle={dashboardCardProperties.onHoldRequestDetail.title}
                                    fill={dashboardCardProperties.onHoldRequestDetail.fill}
                                    rowName="onHoldRow"
                                    requests={requestsSummary.onHoldRequests} />
                            )}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};