import { FC } from 'react';
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import { useSearchContext } from '../../../contexts/SearchContext';
import SearchCriteria from "./SearchCriteria/SearchCriteria";
import { SearchCriteriaEnum, getEnumLabel } from "../../../helpers/Enums";
import { ErrorMessage } from "@hookform/error-message";
import SearchParamModel from '../../../models/SearchParamModel';

const SearchForm: FC = () => {
  const { 
    searchState: { searchParams },
    searchStateActions: { setSearchParams },
    searchFormState,
    searchActions: { onSearchSubmit }
  } = useSearchContext();

  const setSearchTypeChanged = (e: any) => {
    if (e.value >= 0) {
      setSearchParams({...new SearchParamModel(), searchType: Number(e.value)});
    } else {
      setSearchParams({...new SearchParamModel(), searchType: undefined});
    }
  };

  return (
    <Form onSubmit={searchFormState.handleSubmit(onSearchSubmit)} noValidate autoComplete="off">
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col className="fs-5">
              <strong className="mx-2 colorDark">Search</strong>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className="row mb-3">
            <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
              <label><strong>Select your Search Criteria</strong></label>
            </div>
            <div className="col-sm-6">
              <select 
                {...searchFormState.register("searchType", { 
                  required: 'Search Criteria is required.' 
                })}
                onChange={e => setSearchTypeChanged(e.target)}
                className="form-select required"
              >
                <option value="">- Select -</option>
                {Object.keys(SearchCriteriaEnum)
                  .filter(v => !isNaN(Number(v)))
                  .map(key => (
                    <option key={`SearchCriteriaEnum${key}`} value={Number(key)}>
                      {getEnumLabel(SearchCriteriaEnum[Number(key)])}
                    </option>
                  ))
                }
              </select>
              <ErrorMessage 
                errors={searchFormState.formState.errors} 
                name="searchType" 
                render={({ message }) => <span className="error-message">{message}</span>} 
              />
            </div>
            <div className="col-sm-3">
              <Button variant="primary" type="submit">
                <SearchIcon />
                Search
              </Button>
            </div>
          </div>
          <SearchCriteria searchType={searchParams.searchType} />
        </Card.Body>
      </Card>
    </Form>
  );
};

export default SearchForm;