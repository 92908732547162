import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useApplicantService} from "../../../../contexts/ApplicantContext"; 
import ApplicantModel from "../../../../models/ApplicantModel";
import {
    ApplicantEditActionsType,
    ApplicantEditStateActionsType,
    ApplicantEditStateType
} from "../../../../types/ApplicantEditType";
import {pageRouteUrls} from "../../../../helpers/PageRouteUrls";

function useApplicantEdit() {
    //Define ApplicantEdit State
    const location = useLocation();
    const [loading, setLoading] =useState<boolean>(false);
    const [errContactMsg, setErrContactMsg] =useState<string|undefined>(undefined);
    const [btnDisable,setBtnDisable]=useState<boolean>(false);
    const { sourceRequestId } =(location?.state? location.state:-1) as { sourceRequestId: number};
    const { previousPageUrls } = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const { id } = useParams();
    const applicantService = useApplicantService();
    const [applicant, setApplicant] = useState<ApplicantModel|undefined>(new ApplicantModel());
    const applicantEditFormState = useForm<ApplicantModel>({ shouldUnregister: false,defaultValues:new ApplicantModel()});
    const navigate = useNavigate();
    const countryValue = applicantEditFormState.watch('country');

    //functions
    const getCancelDestination = () => {
        if (id && Number.parseInt(id) > 0) {
            return pageRouteUrls.Applicant_Details(id);
        }
        return previousPageUrls?.length > 0 
            ? previousPageUrls[previousPageUrls.length-1] 
            : pageRouteUrls.Home();
    };

    const isCountryCanada = (): boolean => 
        countryValue?.toLowerCase() === "canada";

    const fetchData = async (id: number) => {
        try {
            setBtnDisable(true);
            setLoading(true);
            const data = await applicantService.GetById(id);
            setApplicant(data);
            if (data) {
                applicantEditFormState.reset(data);
            }
        } catch (error) {
            console.error('Error fetching applicant:', error);
        } finally {
            setLoading(false);
            setBtnDisable(false);
        }
    }

    //onload actions
    useEffect(() => {
        if (countryValue) {
            applicantEditFormState.clearErrors("postalCode");
            applicantEditFormState.clearErrors("province");
        }
    }, [countryValue, applicantEditFormState]);

    useEffect(() => {
        if (id) {
            fetchData(parseInt(id));
        }
    }, [id]);

    //Define ApplicantEdit Context
    const applicantEditState = {id, applicant, sourceRequestId, previousPageUrls, loading, btnDisable, errContactMsg, countryValue } as ApplicantEditStateType;
    const applicantEditStateActions = {setErrContactMsg, setApplicant, setLoading, setBtnDisable} as ApplicantEditStateActionsType;
    const applicantEditActions = {getCancelDestination, isCountryCanada, navigate} as ApplicantEditActionsType;

    return {
        applicantEditState,
        applicantEditStateActions,
        applicantEditFormState,
        applicantEditActions
    };
}

export default useApplicantEdit;