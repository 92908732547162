import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {blockInvalidNumberChar} from "../../../../helpers/ValidationUtilities";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import { ActionLabels } from "../../components/ActionLabels";

const PaymentRequestedEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.feeAmount}</Form.Label>
                <Form.Control
                    type="number" {...register("fee", {
                        required: ActionLabels.messages.feeAmountRequired,
                        min: { value: 0, message: ActionLabels.messages.feeAmountInvalid },
                        max: { value: 99999, message: ActionLabels.messages.feeAmountInvalid }
                    })}
                    onKeyDown={blockInvalidNumberChar}
                    onPaste={blockInvalidNumberChar}
                    placeholder={ActionLabels.placeholders.feeAmount}
                    className="required"/>
                <ErrorMessage errors={errors} name="fee" render={({message}) => <span className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default PaymentRequestedEdit;
