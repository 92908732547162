import React from "react";
import Layout from "../../component/Layout";
import { Row, Col, Card, Form, Button, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { storageMng } from "../../helpers/StorageMng";
import { LoadingSpinner } from "../../component/LoadingSpinner";
import { BreadcrumbGenerator } from "../../helpers/BreadcrumbUtilities";
import { useExceptionManager } from "./hooks/useExceptionManager";
import { ExceptionsTable } from "./components/ExceptionsTable";
import { ExceptionsLabels } from './components/ExceptionsLabels';

const MngExceptions: React.FC = () => {
    const location = useLocation();
    const { requestId } = useParams();
    const navigate = useNavigate();
    const userInfo = storageMng.getUserProfile();
    const { previousPageUrls } = (location?.state ?? {}) as { previousPageUrls: string[] };
    
    const {
        loading,
        btnDisable,
        allExceptionTypes,
        selectedExpIds,
        request,
        handleCheckboxChange,
        onSaveClick
    } = useExceptionManager(requestId, previousPageUrls);

    const breadcrumb = BreadcrumbGenerator.generateManageExceptionsBreadcrumb(previousPageUrls, requestId);

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? <LoadingSpinner /> : (
                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col className="fs-5">
                                <strong className="mx-2 colorDark">{ExceptionsLabels.page.title}</strong>
                            </Col>
                            <Col className="textAlign-end">
                                {userInfo?.isAdministrator && (
                                    <Button 
                                        variant="primary" 
                                        onClick={onSaveClick}
                                        disabled={btnDisable}
                                    >
                                        {ExceptionsLabels.buttons.save}
                                    </Button>
                                )}
                                <Link 
                                    to={previousPageUrls[previousPageUrls.length - 1]}
                                    state={{ previousPageUrls }}
                                    className="btn btn-dark"
                                >
                                    {ExceptionsLabels.buttons.cancel}
                                </Link>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-3">
                            <Stack direction="horizontal" gap={3}>
                                <div className="p-2">
                                    <Form.Label>{ExceptionsLabels.page.fileNumber}</Form.Label>
                                </div>
                                <div className="p-2">
                                    <Form.Control plaintext readOnly value={request.fileNumber}/>
                                </div>
                            </Stack>
                        </Row>
                        <Row className="mb-3 px-3">
                            <ExceptionsTable
                                allExceptionTypes={allExceptionTypes}
                                selectedExpIds={selectedExpIds}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </Layout>
    );
};

export default MngExceptions;