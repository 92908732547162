import { FC } from 'react';
import { Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useSearchContext } from '../../../../contexts/SearchContext';
import { blockLessGreaterThanChar } from "../../../../helpers/ValidationUtilities";

const KeywordSearch: FC = () => {
  const { searchFormState } = useSearchContext();

  return (
    <div className="row mb-3">
      <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
        <label><strong>Search By Keyword</strong></label>
      </div>
      <div className="col-sm-6">
        <Form.Control
          type="text"
          {...searchFormState.register("byKeywordTxt", {
            required: 'Keyword is required.',
          })}
          onKeyDown={blockLessGreaterThanChar}
          className="required"
          placeholder="Enter Keyword"
        />
        <ErrorMessage
          errors={searchFormState.formState.errors}
          name="byKeywordTxt"
          render={({ message }) => <span className="error-message">{message}</span>}
        />
      </div>
    </div>
  );
};

export default KeywordSearch;