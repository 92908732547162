import {SubmitHandler} from "react-hook-form";
import {RequestActionModel} from "../../../../models/RequestActionModel";
import {nullableDatesAreSame} from "../../../../helpers/DateUtilities";
import {pageRouteUrls} from "../../../../helpers/PageRouteUrls";
import {useActionService} from "../../../../contexts/ActionContext";
import {ActionEditType} from "../../../../types/ActionEditType";

function useSubmitActionEdit(actionEditContext: ActionEditType) {
    const actionService = useActionService();

    const {
        actionEditState: {requestId, previousPageUrls},
        actionEditStateActions: {setActionData, setBtnDisable},
        actionEditFormState: {formState: {defaultValues}},
        actionEditActions: {navigate}
    } = actionEditContext;

    const confirmFormDirty = (data: RequestActionModel) => {
        if(!nullableDatesAreSame(data.bringForwardDate, defaultValues?.bringForwardDate)
            || !nullableDatesAreSame(data.actionDate, defaultValues?.actionDate)
            || defaultValues?.fee != data.fee
            || defaultValues?.departmentToSearch != data.departmentToSearch
            || defaultValues?.departmentReceivedRecordsFrom != data.departmentReceivedRecordsFrom
            || defaultValues?.extensionDays != data.extensionDays
            || defaultValues?.responseReceived != data.responseReceived
            || defaultValues?.payment?.paymentMethodId != data.payment?.paymentMethodId
            || defaultValues?.payment?.amount !=  data.payment?.amount
            || defaultValues?.payment?.receiptNumber != data.payment?.receiptNumber)
            return true;

        return false;
    };

    const onSubmit: SubmitHandler<RequestActionModel> = async (data) => {
        if(data.id && !confirmFormDirty(data)) {
            navigate(pageRouteUrls.Action_Details(requestId, data.id), {state: {previousPageUrls: previousPageUrls}});
            return;
        }

        setBtnDisable(true);
        setActionData(data);
        const responseSave = await actionService.Save(data);
        if (responseSave) {
            navigate(pageRouteUrls.Action_Details(requestId, responseSave.id), {state: {previousPageUrls: previousPageUrls}});
        }
        setBtnDisable(false);
    };

    return {onSubmit};
};

export default useSubmitActionEdit;
