import { FC } from 'react';
import { ErrorMessage } from "@hookform/error-message";
import { useSearchContext } from '../../../../contexts/SearchContext';
import { RequestTypeEnum, getEnumLabel } from "../../../../helpers/Enums";

const RequestTypeSearch: FC = () => {
  const { searchFormState } = useSearchContext();

  return (
    <div className="row mb-3">
      <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
        <label><strong>Search By RequestType</strong></label>
      </div>
      <div className="col-sm-6">
        <select
          {...searchFormState.register("byRequestTypeId", { 
            required: 'Request Type is required.' 
          })}
          className="form-select required"
        >
          <option key="RequestTypeEnum-1" value="">- Select -</option>
          {Object.keys(RequestTypeEnum)
            .filter((v) => !isNaN(Number(v)))
            .map((key) => (
              <option key={`RequestTypeEnum${key}`} value={Number(key)}>
                {getEnumLabel(RequestTypeEnum[Number(key)])}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={searchFormState.formState.errors}
          name="byRequestTypeId"
          render={({ message }) => <span className="error-message">{message}</span>}
        />
      </div>
    </div>
  );
};

export default RequestTypeSearch;