export const dashboardCardProperties = {
    totalRequest: {title: 'Total Request Received', fill: '#3b719f'},
    overdueRequest: {title: 'Overdue', fill: '#f16767'},
    openRequest: {title: 'Open', fill: '#9673a5'},
    completedRequest: {title: 'Completed', fill: '#197278'},
    carriedForwardRequest: {title: 'Carried Forward', fill: '#F8DE7E'},
    abandonedRequest: {title: 'Abandoned', fill: '#778899'},
    onTrackRequestDetail: {title: 'On Track', fill: 'green'},
    dueWeekRequestDetail: {title: 'Due Within 7 Days', fill: 'orange'},
    overdueRequestDetail: {title: 'Overdue', fill: 'red'},
    onHoldRequestDetail: {title: 'On Hold', fill: 'gray'}
};