export const ActionLabels = {
    // Headers
    headers: {
        actionDetails: "Action Details",
        paymentInformation: "Payment Information",
        searchInformation: "Search Information",
        extensionInformation: "Extension Information",
        responseInformation: "Response Information"
    },

    // Labels
    labels: {
        fileNumber: "File Number",
        actionType: "Action Type",
        actionDate: "Action Date",
        editActionDetails: "Edit Action Details",
        newAction: "New Action",
        bringForwardDueDate: "Bring Forward Due Date",
        
        // Payment Related
        paymentMethod: "Payment Method",
        receiptNumber: "Receipt Number",
        paymentAmount: "Payment Amount",
        feeAmount: "Fee Amount",
        
        // Search Related
        departmentToSearch: "Department To Search",
        departmentReceivedRecordsFrom: "Department Received Records From",
        
        // Extension Related
        extensionDays: "Extension Days",
        
        // Response Related
        responseReceived: "Response Received"
    },

    // Messages
    messages: {
        actionDateRequired: "Action Date is required.",
        actionDateInvalidMin: "The Action Date cannot be prior to the FOIP Request's Date Received.",
        actionDateInvalidMax: "The Action Date cannot be later than today.",
        actionDateInvalid: "The Action Date cannot be prior to the FOIP Request's Date Received.",
        confirmDelete: "Are you sure you want to delete this action item?",
        deleteSuccess: "Deleted successfully",

        // Action Type
        actionTypeRequired: "Action Type is required.",
        extensionDaysRequired: "Extension Days is required.", 
        extensionDaysInvalidMin: "Please enter a valid number of Extension Days.",
        extensionDaysInvalidMax: (maxDays: number) => `Extension days cannot exceed a maximum limit of ${maxDays} days.`,

        // Payment Related
        paymentMethodRequired: "Payment Method is required.",
        receiptNumberRequired: "Receipt Number is required.",
        paymentAmountRequired: "Payment Amount is required.",
        feeAmountRequired: "Fee Amount is required.",
        feeAmountInvalid: "Please enter a valid Fee Amount.",
        receiptNumberInvalid: "Please enter a valid Receipt Number.",
        paymentAmountInvalid: "Please enter a valid Payment Amount."
    },

    // Placeholders
    placeholders: {
        actionDate: "Enter the action date",
        bringForwardDueDate: "Enter the bring forward due date",
        feeAmount: "Enter the fee amount in CAD",
        paymentAmount: "Enter the amount in CAD",
        receiptNumber: "Enter the Receipt Number",
        departmentToSearch: "Enter the department to search",
        departmentReceivedRecordsFrom: "Enter the department received records from",
        extensionDays: "Enter the extension days"
    },
     
    // Buttons
    buttons: {
        delete: "Delete",
        edit: "Edit",
        cancel: "Cancel",
        save: "Save"
    }
}