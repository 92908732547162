import {Form, Row, Col} from "react-bootstrap";
import {Checkbox} from "@mui/material";
import { ActionLabels } from "../components/ActionLabels";

const ResponseReceivedDetails = ({actionData}: any) => {
    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.responseReceived}</Form.Label>
                <span className="ps-3"><Checkbox readOnly checked={actionData.responseReceived} className="checkbox readonly"/></span>
            </Form.Group>
        </Row>
    );
};

export default ResponseReceivedDetails;
