import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import {useApplicantData} from "./hooks/useApplicantData";
import {storageMng} from "../../../helpers/StorageMng";
import {BreadcrumbGenerator} from "../../../helpers/BreadcrumbUtilities";
import Layout from "../../../component/Layout";
import {pageRouteUrls} from "../../../helpers/PageRouteUrls";
import {LoadingSpinner} from "../../../component/LoadingSpinner";
import ApplicantInfoDetails from "./components/ApplicantInfoDetails";
import AddressInfoDetails from "./components/AddressInfoDetails";
import ContactInfoDetails from "./components/ContactInfoDetails";
import { ApplicantLabels } from "../components/ApplicantLabels";

const ApplicantDetails: React.FC = () => {
    const location = useLocation();
    const { id } = useParams();
    const { applicant, loading } = useApplicantData(id);
    const { sourceRequestId } = (location?.state ? location.state : { sourceRequestId: 0 }) as { sourceRequestId: number };
    const { previousPageUrls } = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const userInfo = storageMng.getUserProfile();
    const breadcrumb = BreadcrumbGenerator.generateApplicantBreadcrumb(previousPageUrls, sourceRequestId, id, false);

    return (
        <Layout breadcrumList={breadcrumb}>
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col className="fs-5">
                            <strong className="mx-2 colorDark">{ApplicantLabels.headers.applicantDetails}</strong>
                        </Col>
                        <Col className="textAlign-end">
                            {userInfo?.isAdministrator && (
                                <Link
                                    to={pageRouteUrls.Applicant_Edit(id)}
                                    state={{ sourceRequestId, previousPageUrls }}
                                    className="btn btn-primary">
                                    {ApplicantLabels.buttons.edit}
                                </Link>
                            )}
                            <Link
                                to={previousPageUrls && previousPageUrls.length > 0 ? previousPageUrls[previousPageUrls.length - 1] : pageRouteUrls.Home()}
                                state={{ quApplicantId: id, previousPageUrls }}
                                className="btn btn-dark"
                            >
                                {ApplicantLabels.buttons.cancel}
                            </Link>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        applicant && (
                            <>
                                <Row className="mb-3">
                                    <ApplicantInfoDetails applicant={applicant} />
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <AddressInfoDetails applicant={applicant} />
                                    </Col>
                                    <Col>
                                        <ContactInfoDetails applicant={applicant} />
                                    </Col>
                                </Row>
                            </>
                        )
                    )}
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default ApplicantDetails;