import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BreadcrumbGenerator } from '../../../helpers/BreadcrumbUtilities';
import { useNotificationView } from './hooks/useNotificationsView';
import { NotificationConfigView } from './components/NotificationConfigView';
import Layout from '../../../component/Layout';
import { LoadingSpinner } from '../../../component/LoadingSpinner';
import { pageRouteUrls } from '../../../helpers/PageRouteUrls';
import { Link } from 'react-router-dom';
import { NotificationsLabels } from '../components/NotificationsLabels';

const NotificationsView: React.FC = () => {
    const { loading, configData } = useNotificationView();
    const breadcrumb = BreadcrumbGenerator.generateNotificationViewBreadcrumb();

    return (
        <Layout breadcrumList={breadcrumb}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col className="fs-5">
                                <strong className="mx-2 colorDark">{NotificationsLabels.page.viewTitle}</strong>
                            </Col>
                            <Col className="textAlign-end">
                                <Link to={pageRouteUrls.NotificationsConfig_Edit()} className="btn btn-primary">Edit</Link>
                                <Link to={pageRouteUrls.Home()} className="btn btn-dark">Cancel</Link>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        { loading ? <LoadingSpinner/> 
                                  : <NotificationConfigView configData={configData} />
                        }
                    </Card.Body>
                </Card>
            </LocalizationProvider>
        </Layout>
    );
};

export default NotificationsView;