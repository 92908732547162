import {Form, Row, Col} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";

const SearchSentEdit = () => {
    const actionEditContext = useActionEditContext();
    if(actionEditContext == null){throw new Error("ActionEditContext is not initialized.");}
    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Department To Search</Form.Label>
                <Form.Control
                    type="text" {...register("departmentToSearch", {
                        required: 'Department To Search is required.',
                        maxLength: {value: 255, message: 'Please enter a valid Department To Search.'}
                    })}
                    placeholder="Enter the department to search"
                    className="required"/>
                <ErrorMessage errors={errors} name="departmentToSearch"
                              render={({message}) => <span
                                  className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default SearchSentEdit;
