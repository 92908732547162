import { Button, Spinner } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Layout from "../../../component/Layout";
import { BreadcrumbGenerator } from "../../../helpers/BreadcrumbUtilities";
import { Card } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link } from "react-router-dom";
import { pageRouteUrls } from "../../../helpers/PageRouteUrls";
import ExceptionAddContext from "../../../contexts/ExceptionAddContext";
import useExceptionAdd from "./hooks/useExceptionAdd";
import { ExceptionAdd } from "./components/ExceptionAdd";
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import { ConfigureExceptionsLabels } from "../components/ConfigureExceptionsLabels";

export interface Props {
}

const ConfigureExceptionsAdd: React.FC<Props> = () => {
    const exceptionAddContext = useExceptionAdd();

    const {
        exceptionAddState: {loading, btnDisable},
        exceptionAddFormState: {handleSubmit},
        exceptionAddActions: {onSubmit}
    } = exceptionAddContext;

    const breadcrumb = BreadcrumbGenerator.generateExceptionAddBreadcrumb();

    return (
        <Layout breadcrumList={breadcrumb}>
            <ExceptionAddContext.Provider value={exceptionAddContext}>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <LocalizationProvider dateAdapter={AdapterDayjs}> 
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">
                                            {ConfigureExceptionsLabels.headers.addException}
                                        </strong>
                                    </Col>
                                    <Col className="textAlign-end"> 
                                        <Button variant="primary" type="submit" disabled={btnDisable}>
                                            {ConfigureExceptionsLabels.buttons.save}
                                        </Button> 
                                        <Link to={pageRouteUrls.ConfigureExceptions_View()} className="btn btn-dark">
                                            {ConfigureExceptionsLabels.buttons.cancel}
                                        </Link>
                                    </Col> 
                                </Row>
                            </Card.Header>
                            <Card.Body> 
                                {loading ? (
                                   <LoadingSpinner />
                                ) : (
                                    <ExceptionAdd />
                                )} 
                            </Card.Body>
                        </Card>
                    </LocalizationProvider>
                </Form>  
            </ExceptionAddContext.Provider>      
        </Layout>
    );
};

export default ConfigureExceptionsAdd;