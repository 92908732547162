import React from 'react';
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Stepper, Step, StepLabel, ThemeProvider } from "@mui/material";
import StepperTheme from "../../../../styles/themes/StepperTheme";
import { useRequestEditContext } from '../../../../contexts/RequestEditContext';
import { RequestInfoEdit } from './RequestInfoEdit';
import { pageRouteUrls } from '../../../../helpers/PageRouteUrls';
import { RequestLabels } from "../../components/RequestLabels";

export const RequestProgressEdit: React.FC = () => {

    const { 
        requestEditState: { id, previousPageUrls, btnDisable, steps, activeStep},
        requestEditStateActions: { setBtnDisable},
    } = useRequestEditContext();


   return (
    <Card className="mb-3">
    <Card.Header>
        <Row>
            <Col className="fs-5">
                <strong className="mx-2 colorDark">{RequestLabels.headers.progress}</strong>
            </Col>

            <Col className="textAlign-end"> 
                <Button variant="primary" type="submit" disabled={btnDisable} >{RequestLabels.buttons.save}</Button>
                <Link to={id ? pageRouteUrls.Request_Details(id) : ( previousPageUrls[0] == pageRouteUrls.Search()?pageRouteUrls.Search():pageRouteUrls.Home())} 
                    state={{ previousPageUrls: previousPageUrls }} className="btn btn-dark">
                        {RequestLabels.buttons.cancel}
                </Link> 

            </Col>
        </Row>
    </Card.Header>
    <Card.Body>
        <Container>
            <Row>
                <Col lg="12">
                    <ThemeProvider theme={StepperTheme}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </ThemeProvider>
                </Col>
            </Row>
            <Row className="mb-3"></Row>
            <RequestInfoEdit />
        </Container>
    </Card.Body>
</Card>
   );
};

