import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { EmailExpValidator } from '../../../../helpers/Constants';
import { ErrorMessage } from '@hookform/error-message';
import { blockInvalidNumberChar } from '../../../../helpers/ValidationUtilities';
import IosSwitch from '../../../../component/IosSwitch';
import { useNotificationEditContext } from '../../../../contexts/NotificationEditContext';
import { NotificationsLabels } from '../../components/NotificationsLabels';

export const NotificationConfigEdit: React.FC = () => {
    const {
        notificationEditFormState: { control, register, formState: { errors } }
    } = useNotificationEditContext();

    const maxEmailLength = 320;
    const maxDays = 30;
    const minDays = 1;

    const validateEmails = (value: string | null) => {
        if (value) {
            const emails = value.split(';').map(email => email.trim());
            
            for (const email of emails) {
                if (!email.match(EmailExpValidator)) {
                    return NotificationsLabels.validation.invalidEmail;
                }
                if (email.length > maxEmailLength) {
                    return NotificationsLabels.validation.emailTooLong;
                }
            }
        }
        return true;
    };

    return (
        <Container>
            <Row className="mb-3">
                <Col sm={4} lg={4}>
                    <Form.Label className="pt-2">{NotificationsLabels.form.emailToggle}</Form.Label>
                </Col>
                <Col sm={8} lg={8}>
                    <Controller
                        control={control}
                        name='sendNotifications'
                        render={({ field }) => (
                            <IosSwitch
                                sx={{ m: 1 }}
                                checked={field.value ? field.value : false}
                                onChange={(e: { target: { checked: any; }; }) => field.onChange(e.target.checked)}
                            />
                        )}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={4} lg={4}>
                    <Form.Label className="remove-margin-bottom">{NotificationsLabels.form.recipientEmail}</Form.Label>
                </Col>
                <Col sm={8} lg={8}>
                    <Form.Control
                        type="text"
                        {...register("emailAddress", {
                            validate: (a: string | null) => validateEmails(a)
                        })}
                        placeholder={NotificationsLabels.placeholders.email}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="emailAddress"
                        render={({ message }) => <span className="error-message">{message}</span>}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={4} lg={4}>
                    <Form.Label>{NotificationsLabels.form.dueDateReminder}</Form.Label>
                </Col>
                <Col sm={8} lg={8}>
                    <Form.Control
                        type="number"
                        {...register("dueDateNotificationDays", {
                            max: { value: maxDays, message: NotificationsLabels.validation.daysRange(minDays, maxDays) },
                            min: { value: minDays, message: NotificationsLabels.validation.daysRange(minDays, maxDays) },
                            valueAsNumber: true,
                        })}
                        onKeyDown={blockInvalidNumberChar}
                        onPaste={blockInvalidNumberChar}
                        placeholder={NotificationsLabels.placeholders.days}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dueDateNotificationDays"
                        render={({ message }) => <span className="error-message">{message}</span>}
                    />
                </Col>
            </Row>
        </Container>
    );
};