import {Col, Form, Row} from "react-bootstrap";
import {getEnumLabel, PaymentMethodEnum} from "../../../helpers/Enums";
import { ActionLabels } from "../components/ActionLabels";

const PaymentReceivedDetails = ({actionData}: any) => {
    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} lg="6">
                    <Form.Label>{ActionLabels.labels.paymentMethod}</Form.Label>
                    <span className="ps-4">{getEnumLabel(PaymentMethodEnum[actionData.payment.paymentMethodId])} </span>
                </Form.Group>

                <Form.Group as={Col} lg="6">
                    <Form.Label>{ActionLabels.labels.receiptNumber}</Form.Label>
                    <span className="ps-4">{actionData.payment.receiptNumber} </span>
                </Form.Group>

            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} lg="6">
                    <Form.Label>{ActionLabels.labels.paymentAmount}</Form.Label>
                    <span className="ps-4">{actionData.payment.amount} </span>
                </Form.Group>
            </Row>
        </>
    );
};

export default PaymentReceivedDetails;
