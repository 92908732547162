import React from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import { UseFormRegister } from 'react-hook-form';

interface FormTextFieldProps {
    label: string;
    name: string;
    register: UseFormRegister<any>;
    rules?: object;
    placeholder?: string;
    textarea?: boolean;
    rows?: number;
    type?: string;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onPaste?: (e: React.ClipboardEvent) => void;
    className?: string;
    formState?: any;

}

export const RequestFormInput: React.FC<FormTextFieldProps> = ({
    label,
    name,
    register,
    rules,
    placeholder = `Enter ${label}`,
    textarea = false,
    rows = 3,
    type = 'text',
    onKeyDown,
    onPaste,
    className = 'form-control',
    formState

}) => {
    return (
        <div className="row mb-3">
            <div className="col-sm-4">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-8">
                {textarea ? (
                    <textarea
                        {...register(name, rules)}
                        className={className}
                        placeholder={placeholder}
                        rows={rows}
                        onKeyDown={onKeyDown}
                    />
                ) : (
                    <Form.Control
                        type={type}
                        {...register(name, rules)}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        onPaste={onPaste}
                    />

                )}
                {formState && (
                    <ErrorMessage
                        errors={formState.errors}
                        name={name}
                        render={({ message }) => (
                            <span className="error-message">{message}</span>
                        )}
                    />
                )}
            </div>
        </div>
    );
};