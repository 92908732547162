import {SubmitHandler} from "react-hook-form";
import {pageRouteUrls} from "../../../../helpers/PageRouteUrls";
import ApplicantModel from "../../../../models/ApplicantModel";
import {ApplicantEditType} from "../../../../types/ApplicantEditType";
import {useApplicantService} from "../../../../contexts/ApplicantContext";

function useSubmitApplicantEdit(applicantEditContext: ApplicantEditType) {
    const EMPTY_MASKED_PHONE = '+1 (___) ___-____' as const;

    const applicantService = useApplicantService();

    const {
        applicantEditState: {sourceRequestId, previousPageUrls},
        applicantEditStateActions: {setApplicant, setBtnDisable,setErrContactMsg},
        applicantEditFormState: {formState: {isDirty}},
        applicantEditActions: {navigate}
    } = applicantEditContext;

    const isValidApplicant = (data: ApplicantModel): boolean => {
        const hasContactInfo = data.phoneDay || data.phoneCell || 
            data.phoneEvening || data.phoneFax || data.emailAddress;
        
        if (!hasContactInfo) {
            setErrContactMsg("Please enter a Phone Number or Email Address.");
            return false;
        }
        return true;
    }

    const prepareApplicant = (data: ApplicantModel): ApplicantModel => {
        const phoneFields = ['phoneDay', 'phoneCell', 'phoneEvening', 'phoneFax'] as const;
        
        return {
            ...data,
            IsValid: () => true,
            ...Object.fromEntries(
                phoneFields.map(field => [field, data[field] === EMPTY_MASKED_PHONE ? '' : data[field]])
            )
        };
    }

    const navigateSuccess = (responseSave: ApplicantModel) => {
        const navigationState = { 
            quApplicantId: responseSave.id, 
            previousPageUrls 
        };

        if (sourceRequestId && sourceRequestId > 0) {
            const isFromRequestEdit = previousPageUrls?.at(-1) === pageRouteUrls.Request_Edit(sourceRequestId);
            const targetRoute = isFromRequestEdit 
                ? pageRouteUrls.Request_Edit(sourceRequestId)
                : pageRouteUrls.Request_Details(sourceRequestId);
            
            navigate(targetRoute, { state: navigationState });
        } else if (previousPageUrls?.at(-1)) {
            navigate(previousPageUrls.at(-1)!, { state: navigationState });
        } else {
            navigate(pageRouteUrls.Home());
        }
    }

    const handleApplicantSubmit = async (preparedData: ApplicantModel) => {
        if (!isValidApplicant(preparedData)) {
            return;
        }

        if (preparedData.id === 0 || isDirty) {
            setApplicant(preparedData);
            const responseSave = await applicantService.Save(preparedData);
            if (responseSave) {
                navigateSuccess(responseSave);
            }
        } else {
            navigateSuccess(preparedData);
        }
    }

    const onSubmit: SubmitHandler<ApplicantModel> = async (data) => {
        try {
            setBtnDisable(true);
            setErrContactMsg(undefined);
            const preparedData = prepareApplicant(data);
            await handleApplicantSubmit(preparedData);
        } catch (error) {
            console.error('Error saving applicant:', error);
        } finally {
            setBtnDisable(false);
        }
    }

    return {onSubmit};
};

export default useSubmitApplicantEdit;