import Layout from "../../../component/Layout"
import { useLocation, useParams } from "react-router-dom";
import React from "react";
import { pageRouteUrls } from "../../../helpers/PageRouteUrls";
import {LoadingSpinner} from "../../../component/LoadingSpinner";
import SearchWrapperModel from "../../../models/SearchWrapperModel";
import NavBarState from "../../../models/NavBarState";
import { BreadcrumbGenerator } from "../../../helpers/BreadcrumbUtilities";
import { useRequestData } from "./hooks/useRequestData";
import RequestProgress from "./components/RequestProgress";
import RequestApplicantInfo from "./components/RequestApplicantInfo";
import RequestDetails from "./components/RequestDetails";
import { RequestExceptions } from "../components/RequestExceptions";
import { storageMng } from "../../../helpers/StorageMng";
import RequestActionSummary from "../components/RequestActionSummary";

export interface Props {}

const FOIPRequestDetails: React.FC<Props> = () => {
    const location = useLocation();
    const { id } = useParams();
    const { previousPageUrls } = (location?.state ? location.state : {previousPageUrls:[]}) as { previousPageUrls: string[] };
    const { quSearchWrapper } = (location?.state ? location.state : {quSearchWrapper:null}) as { quSearchWrapper: SearchWrapperModel|null };
    const { request, allActionTypes, steps } = useRequestData(id);
    const breadcrumb = BreadcrumbGenerator.generateRequestBreadcrumb(previousPageUrls,id,false,quSearchWrapper); 
    const userInfo = storageMng.getUserProfile();
    return (
      <Layout breadcrumList={breadcrumb} stateObj={[{NavUrl: pageRouteUrls.Search(),StateObj:quSearchWrapper} as NavBarState]}>
         { !request ? (
             <LoadingSpinner />
            ) : (
            <>
                <RequestProgress request={request} steps={steps} id={id} previousPageUrls={previousPageUrls} quSearchWrapper={quSearchWrapper} />
                <RequestApplicantInfo request={request} id={id} previousPageUrls={previousPageUrls} />
                <RequestDetails request={request} />
                <RequestActionSummary request={request} allActionTypes={allActionTypes} previousPageUrls={previousPageUrls} mode="details" showNewAction={request.id > 0 && userInfo?.isAdministrator} />
                <RequestExceptions requestExceptions={request.requestExceptions} requestId={id} previousPageUrls={previousPageUrls} mode="details" showManageButton={request.id > 0 && userInfo?.isAdministrator} />
            </>
         )}
      </Layout>
    )
}
export default FOIPRequestDetails  