import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FaFolderOpen, FaCheckSquare, FaClipboardList, FaForward, FaStopCircle } from 'react-icons/fa';
import { BsAlarmFill } from 'react-icons/bs'
import {DashboardIconEnum } from "../../helpers/Enums";
import styled from "styled-components";

const dashboardCardMuiSxStyles = {
    mainCard: {
        display: 'flex',
        mt: '0.5rem',
        mb: '0.5rem'
    },
    textBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 2/3,
        height: 'fit-content'
    },
    textBoxCardContent: {
        pt:'0.6rem',
        pb: 0,
        '&:last-child': { pb: 0 }
    },
    iconCardContent: {
        display:'flex',
        margin: 'auto',
    },
    itemsCount: {
        pl: '0.2rem',
        fontWeight: 'bold'
    },
    itemName: {
        fontWeight: '500'
    },

    getIconBoxStyle: (backgroundColor:any) => {
        return({
                display: 'flex',
                width: 1/3,
                backgroundColor: {backgroundColor},
                color: 'white'
        })
    }
};

const renderIcon = (iconType:any) => {
    switch (iconType) {
        case DashboardIconEnum.Open:
            return <FaFolderOpen />
        case DashboardIconEnum.Completed:
            return <FaCheckSquare />
        case DashboardIconEnum.Overdue:
            return <BsAlarmFill />
        case DashboardIconEnum.Total:
            return <FaClipboardList />
        case DashboardIconEnum.CarriedForward:
            return <FaForward />
        case DashboardIconEnum.Abandoned:
            return <FaStopCircle />

        default:
            return <FaFolderOpen />
    }
}

const StyledDashboardIcon = styled('div')`
  & svg {
    font-size:2.4rem;
  }
`;

const DashboardCard = ({iconType, backgroundColor, itemName, itemsCount}: any) => {
  return (
   <Card sx={dashboardCardMuiSxStyles.mainCard}>
       <Box sx={dashboardCardMuiSxStyles.textBox}>
           <CardContent sx={dashboardCardMuiSxStyles.textBoxCardContent}>
               <Typography component="div" variant="h6" noWrap sx={dashboardCardMuiSxStyles.itemName}>
                   {itemName}
               </Typography>
               <Typography variant="subtitle1" component="div" sx={dashboardCardMuiSxStyles.itemsCount}>
                   <strong>{itemsCount}</strong>
               </Typography>
           </CardContent>
       </Box>
       <Box sx={dashboardCardMuiSxStyles.getIconBoxStyle(backgroundColor)}>
           <CardContent sx={dashboardCardMuiSxStyles.iconCardContent}>
               <StyledDashboardIcon>
                { renderIcon(iconType) }
               </StyledDashboardIcon>
           </CardContent>
       </Box>
   </Card>
  );
};

export default DashboardCard;
