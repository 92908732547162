import { useState, useEffect } from 'react';
import { RequestExceptionModel } from '../../../models/RequestExceptionModel';
import ExceptionTypeModel from '../../../models/ExceptionTypeModel';
import { useExceptionService } from '../../../contexts/ExceptionContext';
import { useRequestService } from '../../../contexts/RequestContext';
import Swal from 'sweetalert2';
import { ExceptionsLabels } from '../components/ExceptionsLabels';
import { useNavigate } from 'react-router-dom';
import RequestModel from '../../../models/RequestModel';

export const useExceptionManager = (requestId: string | undefined, previousPageUrls?: string[]) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [requestExceptions, setRequestExceptions] = useState<RequestExceptionModel[]>([]);
    const [allExceptionTypes, setAllExceptionTypes] = useState<ExceptionTypeModel[]>([]);
    const [selectedExpIds, setSelectedExpIds] = useState<number[]>([]);
    const [request, setRequest] = useState<RequestModel>(new RequestModel());
    
    const exceptionService = useExceptionService();
    const requestService = useRequestService();
    useEffect(() => {
        if (requestId) {
            fetchData();
        }
    }, [requestId]);

    const fetchRequest = async () => {
        const rqst = await requestService.GetById(parseInt(requestId!));
        if (rqst) setRequest({...rqst});
    }

    const fetchData = async () => {
        setBtnDisable(true);
        setLoading(true);
        try {
            const allExceptionTypes = await exceptionService.GetExceptionTypes();
            setAllExceptionTypes(allExceptionTypes);
            
            const rqstExcepts = await exceptionService.GetRequestExceptionsByRequestId(parseInt(requestId!));
            setSelectedExpIds(rqstExcepts.map((exception: RequestExceptionModel) => exception.exceptionTypeId));
            setRequestExceptions(rqstExcepts);
            fetchRequest();
           
        } finally {
            setLoading(false);
            setBtnDisable(false);
        }
    };

    const handleSave = async () => {
        setBtnDisable(true);
        setLoading(true);
        try {
            if (requestId && parseInt(requestId) > 0) {
                const exceptions = await exceptionService.Save(parseInt(requestId), selectedExpIds);
                setRequestExceptions(exceptions);
                return true;
            }
        } finally {
            setLoading(false);
            setBtnDisable(false);
        }
        return false;
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const changedId = parseInt(event.target.value);
        const selectedType = allExceptionTypes.find(a => a.id === changedId);

        if (checked) {
            setSelectedExpIds(prev => [...prev, changedId]);
        } else {
            confirmUnselect(event, changedId, selectedType);
        }
    };

    const confirmUnselect = (event: React.ChangeEvent<HTMLInputElement>, changedId: number, selectedType?: ExceptionTypeModel) => {
        Swal.fire({
            title: ExceptionsLabels.confirmation.unselect.title,
            html: `${selectedType?.code} | ${selectedType?.name}`,
            showCancelButton: true,
            confirmButtonText: ExceptionsLabels.confirmation.unselect.confirm,
            cancelButtonText: ExceptionsLabels.confirmation.unselect.cancel,
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedExpIds(prev => prev.filter(e => e !== changedId));
            } else {
                event.target.checked = true;
            }
        });
    };

    const onSaveClick = async () => {
        const success = await handleSave();
        if (success && previousPageUrls) {
            navigate(previousPageUrls[previousPageUrls.length - 1], {
                state: { previousPageUrls }
            });
        }
    };

    return {
        loading,
        btnDisable,
        allExceptionTypes,
        selectedExpIds,
        request,
        handleSave,
        handleCheckboxChange,
        onSaveClick
    };
};