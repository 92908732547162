import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import { encode } from "html-entities";
import RequestModel from "../../../../models/RequestModel";
import { RequestTypeEnum } from "../../../../helpers/Enums";
import { formatDateAs } from "../../../../helpers/DateUtilities";
import { DateFormat } from "../../../../helpers/Constants";
import { RequestLabels } from "../../components/RequestLabels";

interface RequestDetailsProps {
    request: RequestModel;
}

const RequestDetails: React.FC<RequestDetailsProps> = ({ request }) => {
    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                        <Col className="fs-5">
                            <strong className="mx-2 colorDark">{RequestLabels.headers.requestDetails}</strong>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col>
                        <Card className="mb-2">
                            <Card.Header><strong>{RequestLabels.headers.processingDetails}</strong></Card.Header>
                            <Card.Body>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.requestSummary}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <ShowMoreText
                                            lines={2}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            expanded={false}
                                            width={370}
                                            keepNewLines={true}
                                            truncatedEndingComponent={"... " && <br />}
                                        >
                                            {encode(request.requestSummary)}
                                        </ShowMoreText>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.headers.requestDetails}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <ShowMoreText
                                            lines={3}
                                            more="Show more"
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="show-more-less-clickable"
                                            expanded={false}
                                            width={370}
                                            keepNewLines={true}
                                            truncatedEndingComponent={"... "}
                                        >
                                            {encode(request.requestText)}
                                        </ShowMoreText>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.requestType}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.requestTypeName} />
                                    </div>
                                </div>

                                {request.requestTypeId === RequestTypeEnum.General && (
                                    <div className="row mb-3">
                                        <div className="col-sm-4">
                                            <Form.Label>{RequestLabels.labels.requestSource}</Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <Form.Control plaintext readOnly value={request.requesterSourceName} />
                                        </div>
                                    </div>
                                )}

                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.documentLocation}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.sharepointUrl} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="mb-2">
                            <Card.Header>
                                <strong>{RequestLabels.headers.responseDetails}</strong>
                            </Card.Header>
                            <Card.Body>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.recordDisclosed}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.disclosureName} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.disposition}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.dispositionName} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.releaseStatus}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.releaseStatusName} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.deliveryMethod}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.deliveryMethodName} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mb-2">
                            <Card.Header>
                                <strong>{RequestLabels.headers.completedDetails}</strong>
                            </Card.Header>
                            <Card.Body>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.numberOfPages}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={request.numberOfPages ?? ""} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Form.Label>{RequestLabels.labels.completedDate}</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control plaintext readOnly value={formatDateAs(request.completedDate, DateFormat)} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RequestDetails;