import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import DashboardChart from '../../../component/dashboard/DashboardChart';
import { dashboardCardProperties } from '../hooks/dashboardCardProperties';
import RequestsSummaryModel from "../../../models/RequestsSummaryModel";
import { LoadingSpinner } from '../../../component/LoadingSpinner';

interface Props {
    loading: boolean;
    requestsSummary: RequestsSummaryModel;
}

export const ActiveRequestsSummary: React.FC<Props> = ({ loading, requestsSummary }) => {
    const chartData = [
        {
            name: dashboardCardProperties.onTrackRequestDetail.title,
            value: requestsSummary.onTrackRequestsCount,
            fill: dashboardCardProperties.onTrackRequestDetail.fill
        },
        {
            name: dashboardCardProperties.dueWeekRequestDetail.title,
            value: requestsSummary.dueWeekRequestsCount,
            fill: dashboardCardProperties.dueWeekRequestDetail.fill
        },
        {
            name: dashboardCardProperties.overdueRequestDetail.title,
            value: requestsSummary.overdueRequestsCount,
            fill: dashboardCardProperties.overdueRequestDetail.fill
        },
        {
            name: dashboardCardProperties.onHoldRequestDetail.title,
            value: requestsSummary.onHoldRequestsCount,
            fill: dashboardCardProperties.onHoldRequestDetail.fill
        }
    ];

    return (
        <Card className="same-height">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">Active Requests Summary</strong>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container>
                    {loading ? <LoadingSpinner /> : <DashboardChart chartData={chartData} />}
                </Container>
            </Card.Body>
        </Card>
    );
};