import {Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {validateDate} from "../../../../helpers/DateUtilities";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import {ErrorMessage} from "@hookform/error-message";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import { ActionLabels } from "../../components/ActionLabels";

const ActionDateEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditState: {actionType, minActionDate, maxActionDate},
        actionEditFormState: {control, formState: {errors}, setValue},
        actionEditActions: {setBringForwardMinMaxDates}
    } = actionEditContext;

    const setBringForwardDateOnActionChange = (date: Dayjs | null) => {
        if (!actionType?.requiresBringForwardDate || !date) return;

        let bfDate = date.add(actionType?.bringForwardMaxDaysForward, 'day');
        setValue("bringForwardDate", bfDate.toDate());
    };

    return (
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.actionDate}</Form.Label>
                <Controller
                    control={control}
                    name='actionDate'
                    rules={{
                        required: ActionLabels.messages.actionDateRequired,
                        validate: (date) => validateDate(date
                            , ActionLabels.labels.actionDate
                            , minActionDate
                            , ActionLabels.messages.actionDateInvalidMin
                            , maxActionDate
                            , ActionLabels.messages.actionDateInvalidMax)
                    }}
                    render={({field, fieldState: { error }}) => (
                        <DatePicker
                            views={['year', 'month', 'day']}
                            onChange={(date) => {
                                field.onChange(date);
                                setBringForwardMinMaxDates(date);
                                setBringForwardDateOnActionChange(date);
                            } }
                            value={field.value ? dayjs(field.value) : null}
                            minDate={ minActionDate }
                            maxDate={ maxActionDate }
                            slotProps={{
                                textField: {
                                    placeholder: ActionLabels.placeholders.actionDate,
                                    className: "form-control requiredDate",
                                }
                            }}
                        />
                    )}
                />
                <ErrorMessage errors={errors} name="actionDate" render={({message}) => <span
                    className="error-message">{message}</span>}/>

            </Form.Group>
            );
}

export default ActionDateEdit;
