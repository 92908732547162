import React, { useState , useEffect} from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav'; 
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { storageMng } from '../../helpers/StorageMng';
import UserModel from '../../models/UserModel';
import { AccessTypeEnum, getEnumLabel } from '../../helpers/Enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser, faAngleRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import authUtility from "../../helpers/AuthUtilities";

/**
 * Renders a sign out button 
 */
export const UserStatusButton = () => {
  const { instance, accounts } = useMsal();
  const [userInfo, setUserInfo] = useState<UserModel>();
  const faUserPropIcon = faUser as IconProp;
  const faAngleRightPropIcon = faAngleRight as IconProp;
  const faSignOutAltPropIcon = faSignOutAlt as IconProp;

  useEffect(() => {
      const accountInfo = accounts[0] ?? {}
      setUserInfo(new UserModel(accountInfo));
      storageMng.storeUserProfile(accountInfo);
  }, [accounts, instance]);

  return (
    <>
        <Nav className="navbar-nav mb-2 mb-lg-0">
            <Nav.Item className="nav-item dropdown">
                <AuthenticatedTemplate>
                    <NavDropdown className="active" title={<><FontAwesomeIcon icon={faUserPropIcon} /><span className="menu-text">{userInfo ? userInfo.name : ""}</span></>}>
                        <NavDropdown.Item>
                            <div id="userTemplate" className="popover-body">
                                <div>
                                    <span className="h6 text-decoration-underline">Roles</span>
                                </div>
                                <div className="p-1">
                                      {userInfo?.role?.length?                                      
                                        userInfo?.role
                                        .filter(role=> role!= AccessTypeEnum.None)
                                        .map((role,index) => 
                                          (
                                              <div key={`role-container-${index}`}>
                                                  <FontAwesomeIcon key={`role-icon-${index}`} icon={faAngleRightPropIcon} />
                                                  <span className="ps-1" key={`role-${index}`}>
                                                      {getEnumLabel(AccessTypeEnum[role])}
                                                  </span>
                                              </div>
                                          ))
                                        :
                                        (
                                          <span className="ps-1">Guest</span>
                                        ) 
                                      }
                                </div> 
                            </div> 
                        </NavDropdown.Item>
                        <hr/> 
                        <NavDropdown.Item onClick={() => authUtility.handleLogout()}>
                            <span className="color-blue">
                                <FontAwesomeIcon icon={faSignOutAltPropIcon} /> <span className="text-decoration-underline">Sign Out</span>
                            </span>
                        </NavDropdown.Item>

                        
                    </NavDropdown>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <NavDropdown className="active" title={<><FontAwesomeIcon icon={faUserPropIcon} /></>}>
                        <NavDropdown.Item onClick={() => authUtility.handleLogin()}>
                            Sign In
                        </NavDropdown.Item> 
                    </NavDropdown>  
                </UnauthenticatedTemplate>
            </Nav.Item>
        </Nav>
    </>
    
  );
};