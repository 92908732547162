import {Button} from "react-bootstrap";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import Swal from "sweetalert2";
import {pageRouteUrls} from "../../../../helpers/PageRouteUrls";
import {useActionService} from "../../../../contexts/ActionContext";
import { ActionLabels } from "../../components/ActionLabels";

const ActionDelete = () => {
    const actionEditContext = useActionEditContext();
    const actionService = useActionService();

    const {
        actionEditState: {id, requestId, btnDisable, previousPageUrls},
        actionEditStateActions: {setBtnDisable},
        actionEditActions: {navigate}
    } = actionEditContext;

    const confirmDelete = (async () => {
        setBtnDisable(true);
        Swal.fire({
            html: `<strong>${ActionLabels.messages.confirmDelete}</strong>`,
            showDenyButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (!result.isConfirmed) return;
            deleteClick();
        })
        setBtnDisable(false);
    });

    const deleteClick = (async () => {
        if (!id) return;
        const result = await actionService.Delete(parseInt(id));
        if (result !== true) return;

        Swal.fire({
            icon: 'success',
            title: ActionLabels.messages.deleteSuccess,
            showConfirmButton: false,
            timer: 1500
        }).then(() => {
            (previousPageUrls && previousPageUrls.length > 0) ?
                navigate(previousPageUrls.at(-1)!, {state: {previousPageUrls: previousPageUrls}}) :
                navigate(pageRouteUrls.Request_Details(requestId));
        });
    });

    return (
        (id && Number.parseInt(id) > 0) ?
            (<Button variant="danger" onClick={confirmDelete} disabled={btnDisable}>{ActionLabels.buttons.delete}</Button>)
            : (<></>)
    );
};

export default ActionDelete;
