import {Container, Nav} from "react-bootstrap"
import {config} from "../config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Navigationbar from "./NavigationBar"
import {Link, useNavigate} from "react-router-dom";   
import NavBarState from "../models/NavBarState";

export interface HeaderProps {
    breadcrumList:any,
    stateObj?:[NavBarState];
}
  
export const Header = ({breadcrumList, stateObj}: HeaderProps) => {
    const navigation = useNavigate();
    const handleGoToScreen = (link: any) => {
        navigation(link);
    };
    const faHomePropIcon = faHome as IconProp;
    const hasBreadcrumbs = breadcrumList?.length > 0;
    const navClasses = `navbar navbar-dark bg-${config.ApplicationEnvironment}`;

    return (
        <> 
            <Nav className={navClasses}>
                <Container className="container-fluid">
                    <div className="navbar-brand d-none d-md-block pt-3 pb-3">
                        <div>
                            <img className="d-inline-block align-middle cbelogo" src="/images/CBELogo.png"
                                 alt="Calgary Board of Education"/>
                            <h2 className="d-lg-inline-block d-md-none align-middle ps-2">{config.ApplicationTitle}</h2>
                            <h2 className="d-md-inline-block d-lg-none align-middle ps-2">{config.ApplicationShortName}</h2>
                        </div>
                    </div>
                    <a className="navbar-brand d-block d-md-none pt-2" asp-area="" asp-controller="Home"
                       asp-action="Index">
                        <img className="d-inline-block align-middle cbelogo" src="/images/CBELogo.png"
                             alt="Calgary Board of Education"/>
                        <h3 className="d-inline-block align-middle ps-2">{config.ApplicationShortName}</h3>
                    </a>
                    <a className="navbar-brand applogo">
                        <img className="d-inline-block align-middle cbelogo shadow-sm rounded icon"
                             src="/images/FOIP-logo.svg" alt="CBE Contact Lists"/>
                    </a>
                </Container>
            </Nav>
            <Navigationbar stateObj={stateObj}></Navigationbar>

            <Container>

                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            { hasBreadcrumbs ? (
                                <a href="/home" >
                                    <FontAwesomeIcon icon={faHomePropIcon} className="fa-lg" />
                                </a>
                            ): <></>
                            }
                        </li>
                        {/* GENERATING BREADCRUMB FROM THE LIST PROVIDE BY THE CURRENT PAGE */}
                        {breadcrumList?.map((item: any, index: any) => (
                            <li className="breadcrumb-item" key={index}>
                                {item.link ? (<Link to={item.link} state={item.state}>{item.title}</Link>) : (<span>{item.title}</span>)}
                            </li>
                        ))}
                    </ol>
                </nav>

            </Container>
        </>
    )
}