import { FC } from 'react';
import { SearchCriteriaEnum } from "../../../../helpers/Enums";
import ApplicantSearch from './ApplicantSearch';
import DateRangeSearch from './DateRangeSearch';
import FileNumberSearch from './FileNumberSearch';
import KeywordSearch from './KeywordSearch';
import RequestTypeSearch from './RequestTypeSearch';

interface Props {
  searchType?: SearchCriteriaEnum;
}

const SearchCriteria: FC<Props> = ({ searchType }) => {
  switch (Number(searchType)) {
    case SearchCriteriaEnum.Applicant:
      return <ApplicantSearch />;
    case SearchCriteriaEnum.DateRange:
      return <DateRangeSearch />;
    case SearchCriteriaEnum.RequestType:
      return <RequestTypeSearch />;
    case SearchCriteriaEnum.FileNumber:
      return <FileNumberSearch />;
    case SearchCriteriaEnum.Keyword:
      return <KeywordSearch />;
    case SearchCriteriaEnum.ActiveRequests:
    case SearchCriteriaEnum.CompletedRequests:
      return null;
    default:
      return null;
  }
};

export default SearchCriteria;