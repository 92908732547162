import { useState, useEffect } from 'react';
import { useRequestService } from "../../../contexts/RequestContext";
import RequestsSummaryModel from "../../../models/RequestsSummaryModel";

export const useRequestsSummary = () => {
    const [requestsSummary, setRequestsSummary] = useState<RequestsSummaryModel>(new RequestsSummaryModel());
    const [loading, setLoading] = useState<boolean>(false);
    const requestService = useRequestService();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const rqsts = await requestService.GetRequestsSummary();
            if (rqsts) {
                setRequestsSummary(rqsts);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return { requestsSummary, loading };
};