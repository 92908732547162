import React, {useContext} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {blockInvalidWholeNumberChar} from "../../../../helpers/ValidationUtilities";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";
import { ActionLabels } from "../../components/ActionLabels";

const ExtensionRequestedEdit = () => {
    const actionEditContext = useActionEditContext();
    const minExtensionDays = 0;
    const maxExtensionDays = 30;

    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>{ActionLabels.labels.extensionDays}</Form.Label>
                <Form.Control
                    type="number" {...register("extensionDays", {
                        required: ActionLabels.messages.extensionDaysRequired,
                        min: {value: minExtensionDays, message: ActionLabels.messages.extensionDaysInvalidMin},
                        max: {value: maxExtensionDays, message: ActionLabels.messages.extensionDaysInvalidMax(maxExtensionDays)}
                    })}
                    onKeyDown={blockInvalidWholeNumberChar}
                    onPaste={blockInvalidWholeNumberChar}
                    placeholder={ActionLabels.placeholders.extensionDays}
                    className="required"/>

                <ErrorMessage errors={errors} name="extensionDays"
                              render={({message}) => <span
                                  className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default ExtensionRequestedEdit;
