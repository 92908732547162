import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { NotificationConfigModel } from '../../../../models/NotificationConfigModel';
import { NotificationsLabels } from '../../components/NotificationsLabels';

interface Props {
    configData: NotificationConfigModel;
}

export const NotificationConfigView: React.FC<Props> = ({ configData }) => (
    <Container>
        <Row className="mb-3">
            <Col sm={4} lg={4}>
                <Form.Label>{NotificationsLabels.form.emailToggle}</Form.Label>
            </Col>
            <Col sm={8} lg={8}>
                <Form.Control plaintext readOnly value={configData.sendNotifications ? 'ON' : 'OFF'} />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4} lg={4}>
                <Form.Label className="remove-margin-bottom">{NotificationsLabels.form.recipientEmail}</Form.Label>
            </Col>
            <Col sm={8} lg={8}>
                <Form.Control plaintext readOnly value={configData["emailAddress"] ?? ''} />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4} lg={4}>
                <Form.Label>{NotificationsLabels.form.dueDateReminder}</Form.Label>
            </Col>
            <Col sm={8} lg={8}>
                <Form.Control plaintext readOnly value={configData["dueDateNotificationDays"] ?? ''} />
            </Col>
        </Row>
    </Container>
);