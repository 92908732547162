export const ExceptionsLabels = {
    page: {
        title: 'Exceptions Applied',
        noData: 'No Data found',
        fileNumber: 'File Number'
    },
    table: {
        headers: {
            exceptionCode: 'Exception Code',
            description: 'Description'
        }
    },
    buttons: {
        save: 'Save',
        cancel: 'Cancel'
    },
    confirmation: {
        unselect: {
            title: 'Are you sure you want to unselect the exception ?',
            confirm: 'Yes',
            cancel: 'No'
        }
    }
};