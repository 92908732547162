import ExceptionTypeModel from "../models/ExceptionTypeModel";
import { RequestExceptionModel } from "../models/RequestExceptionModel";
import {IHttpRequestService} from "./HttpRequestService";

export interface IExceptionService {
    ExistsByCode(code: string):Promise<boolean |undefined>;
    GetExceptionTypes():Promise<ExceptionTypeModel[]>;
    AddExceptionType(data:ExceptionTypeModel):Promise<ExceptionTypeModel|undefined>;
    DeleteExceptionType(ids:number[]):Promise<boolean|undefined>;
    Add(data:RequestExceptionModel ):Promise<RequestExceptionModel|undefined>;
    Delete(ids:number[]):Promise<boolean|undefined>;
    Save(requestId:number,selectedExpIds:number[]):Promise<RequestExceptionModel[]>;
    GetRequestExceptionsByRequestId(requestId:number ):Promise<RequestExceptionModel[]>;
    GetRequestExceptionsByExceptionTypeIds(ids:number[] ):Promise<RequestExceptionModel[]>;
}
 
export class ExceptionService implements IExceptionService {
    constructor(private httpRequestService: IHttpRequestService) {}

    public async ExistsByCode(code: string):Promise<boolean |undefined>{
        try{  
            const encodedCode = encodeURIComponent(code);
            const { data } = await this.httpRequestService.Get(`/api/v1/ExceptionType/ExistsByCode/${encodedCode}`);
            return data as boolean;
                
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async GetExceptionTypes():Promise<ExceptionTypeModel[]>{
        try{   
            const { data } = await this.httpRequestService.Get(`/api/v1/ExceptionType`);
            return data as ExceptionTypeModel[];
                
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return [];
    }

    public async AddExceptionType(data:ExceptionTypeModel):Promise<ExceptionTypeModel|undefined>{
        try{  
            const response  = 
                await this.httpRequestService.Post(`/api/v1/ExceptionType/Add`,data);
            
            if(response)
                return response as ExceptionTypeModel;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }
    public async DeleteExceptionType(ids:number[]):Promise<boolean|undefined>{
        try{   
            const response  = 
                await this.httpRequestService.Delete(`/api/v1/ExceptionType`,ids);
            
            if(response)
                return response as boolean;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Add(data:RequestExceptionModel ):Promise<RequestExceptionModel|undefined>{
        try{  
            const response  = 
                await this.httpRequestService.Post(`/api/v1/RequestException/Add`,data);
            
            if(response)
                return response as RequestExceptionModel;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Delete(ids:number[]):Promise<boolean|undefined>{
        try{  
            const response  =
                await this.httpRequestService.Delete(`/api/v1/RequestException`,ids);
            if(response)
                return response as boolean;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Save(requestId:number,selectedExpIds:number[]):Promise<RequestExceptionModel[]>{
        try{  
            const response  = 
                await this.httpRequestService.Post(`/api/v1/RequestException/Save`,{ RequestId :requestId,ExceptionTypeIds:selectedExpIds});
            
            if(response)
                return response as RequestExceptionModel[];
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return [];
    }

    public async GetRequestExceptionsByRequestId(requestId:number ):Promise<RequestExceptionModel[]>{
        try{  
            let { data } = await this.httpRequestService.Get(`/api/v1/RequestException/ByRequestId/${requestId}`);
            return data as RequestExceptionModel[];
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return []; 
    }

    public async GetRequestExceptionsByExceptionTypeIds(ids:number[] ):Promise<RequestExceptionModel[]>{
        try{  
            const queryString = ids.join(',');  
            let { data } = await this.httpRequestService.Get(`/api/v1/RequestException/ByExceptionTypeIds/${queryString}`);
            return data as RequestExceptionModel[];
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return []; 
    }
     
  };