export class pageRouteUrls
{
    public static Home(): string  {
        return  `/Home`;
    }
    public static Search(): string  {
        return  `/Search`;
    }
    public static Request_Add(): string {
        return  `/FOIPRequest/Add`; 
    }
    public static Request_Edit(id:any): string {
        if(!id || id==0)
            return this.Request_Add();
        else
            return `/FOIPRequest/Edit/${id}`; 
    }
    public static Request_Details(id:any): string {
        return `/FOIPRequest/Details/${id}`; 
    } 
  
    public static Action_Add(requestId:any): string {
        return  `/FOIPRequest/${requestId}/Action/Add`; 
    }
    public static Action_Edit(requestId:any,id:any): string {
        if(!id || id==0)
            return this.Action_Add(requestId);
        else
            return `/FOIPRequest/${requestId}/Action/Edit/${id}`; 
    }
    public static Action_Details(requestId:any,id:any): string {
        return `/FOIPRequest/${requestId}/Action/Details/${id}`; 
    }          
    public static Applicant_Add(): string {
        return  `/Applicant/Add`; 
    }
    public static Applicant_Edit(id:any): string {
        if(!id || id==0)
            return this.Applicant_Add();
        else
            return `/Applicant/Edit/${id}`; 
            
    }
    public static Applicant_Details(id:any): string {
         return `/Applicant/Details/${id}`; 
    }     

    public static MngExceptions(requestId:any): string {
        return  `/FOIPRequest/${requestId}/MngExceptions`; 
    }

    public static Reports(): string {
        return `/Reports/Reports`; 
    }    

    public static NotificationsConfig_View(): string {
        return  `/Notifications/Config`; 
    }  
    public static NotificationsConfig_Edit(): string {
        return  `/Notifications/Edit`; 
    }
    public static ConfigureExceptions_View():string{
        return  `/ConfigureExceptions/View`;         
    }
    public static ConfigureExceptions_Add():string{
        return  `/ConfigureExceptions/Add`;         
    }
}