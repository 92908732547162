import React from 'react';
import { Card } from 'react-bootstrap';
import { RequestFormInput } from './RequestFormInput';
import { RequestFormSelect } from './RequestFormSelect';
import { useRequestEditContext } from '../../../../contexts/RequestEditContext';
import { RequesterSourceTypeEnum, RequestTypeEnum } from '../../../../helpers/Enums';
import { getEnumLabel } from '../../../../helpers/Enums';
import { blockLessGreaterThanChar } from '../../../../helpers/ValidationUtilities';
import { RequestLabels } from "../../components/RequestLabels";

export const RequestProcessingEdit: React.FC = () => {
    const { 
        requestEditState: { id, request, requestTypeId },
        requestEditStateActions: { setRequestTypeId },
        requestEditFormState: { register, formState }
    } = useRequestEditContext();

    const requestTypeOptions = Object.keys(RequestTypeEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => ({
            value: Number(key),
            label: getEnumLabel(RequestTypeEnum[Number(key)])
        }));

    const requesterSourceOptions = Object.keys(RequesterSourceTypeEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => ({
            value: Number(key),
            label: getEnumLabel(RequesterSourceTypeEnum[Number(key)])
        }));

    const fieldValidation = {
        requestSummary: {
            required: 'The Request Summary is required.',
            maxLength: { 
                value: 256, 
                message: "The Request Summary cannot be more than 256 characters." 
            }
        },
        requestText: {
            required: 'The Request Details is required.',
            maxLength: { 
                value: 4000, 
                message: "The Request Details cannot be more than 4000 characters." 
            }
        },
        requestTypeId: {
            required: 'The Request Type is required.'
        },
        requesterSourceTypeId: {
            required: 'The Request Source is required.'
        }
    };

    return (
        <Card className="mb-2">
            <Card.Header><strong>{RequestLabels.headers.processingDetails}</strong></Card.Header>
            <Card.Body>
                <RequestFormInput
                    label={RequestLabels.labels.requestSummary}
                    name="requestSummary"
                    register={register}
                    rules={fieldValidation.requestSummary}
                    textarea
                    rows={4}
                    onKeyDown={blockLessGreaterThanChar}
                    className="form-control required"
                    formState={formState}
                />

                <RequestFormInput
                    label={RequestLabels.headers.requestDetails}
                    name="requestText"
                    register={register}
                    rules={fieldValidation.requestText}
                    textarea
                    rows={5}
                    onKeyDown={blockLessGreaterThanChar}
                    className="form-control required"
                    formState={formState}
                />

                {id && Number.parseInt(id) > 0 ? (
                    <div className="row mb-3">
                        <div className="col-sm-4">
                            <label><strong>{RequestLabels.labels.requestType}</strong></label>
                        </div>
                        <div className="col-sm-8">
                            <input 
                                className="form-control-plaintext" 
                                readOnly 
                                value={request?.requestTypeName} 
                            />
                        </div>
                    </div>
                ) : (
                    <RequestFormSelect
                        label={RequestLabels.labels.requestType}
                        name="requestTypeId"
                        register={register}
                        rules={fieldValidation.requestTypeId}
                        options={requestTypeOptions}
                        onChange={(e) => setRequestTypeId(e.target.value ? Number(e.target.value) : null)}
                        className="form-select required"
                        formState={formState}
                    />
                )}

                {requestTypeId === RequestTypeEnum.General && (
                    <RequestFormSelect
                        label={RequestLabels.labels.requestSource}
                        name="requesterSourceTypeId"
                        register={register}
                        rules={fieldValidation.requesterSourceTypeId}
                        options={requesterSourceOptions}
                        className="form-select required"
                        formState={formState}
                    />
                )}

                <RequestFormInput
                    label={RequestLabels.labels.documentLocation}
                    name="sharepointUrl"
                    register={register}
                    placeholder={RequestLabels.placeholders.documentLocation}
                    formState={formState}
                />
            </Card.Body>
        </Card>
    );
};