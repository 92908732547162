import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage } from "@hookform/error-message";
import dayjs from "dayjs";
import { reportLabels } from './ReportLabels';

interface ReportDateRangePickerProps {
  control: any;
  errors: any;
  getValues: any;
  validateDate2: any;
}

const ReportDateRangePicker: React.FC<ReportDateRangePickerProps> = ({
  control,
  errors,
  getValues,
  validateDate2,
}) => {
  return (
    <Row className="mb-3">
      <Col sm={3} lg={2}>
        <Form.Label>{reportLabels.reportingPeriod}</Form.Label>
      </Col>
      <Col sm={4} lg={5}>
        <div className="d-flex align-items-center gap-2">
          <Form.Label className="mb-0 pr-2">{reportLabels.fromDate}</Form.Label>
          <div className="flex-grow-1">
            <Controller
              control={control}
              name="ByDateFrom"
              rules={{
                required: reportLabels.dateRangeRequired,
                validate: (date) =>
                  validateDate2(
                    date,
                    reportLabels.fromDate,
                    getValues().ByDateFrom,
                    reportLabels.invalidFromDate,
                    undefined,
                    undefined
                  ),
              }}
              render={({ field }) => (
                <DatePicker
                  maxDate={dayjs()}
                  views={["year", "month", "day"]}
                  slotProps={{
                    textField: {
                      placeholder: reportLabels.fromDatePlaceholder,
                      className: "form-control requiredDate",
                    },
                  }}
                  onChange={(date) => field.onChange(date)}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="ByDateFrom"
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
        </div>
      </Col>
      <Col sm={4} lg={5}>
        <div className="d-flex align-items-center gap-2">
          <Form.Label className="mb-0 pr-2">{reportLabels.toDate}</Form.Label>
          <div className="flex-grow-1">
            <Controller
              control={control}
              name="ByDateTo"
              rules={{
                required: reportLabels.dateRangeRequired,
                validate: (date) =>
                  validateDate2(
                    date,
                    reportLabels.toDate,
                    getValues().ByDateFrom,
                    reportLabels.invalidToDate,
                    undefined,
                    undefined
                  ),
              }}
              render={({ field }) => (
                <DatePicker
                  maxDate={dayjs()}
                  views={["year", "month", "day"]}
                  slotProps={{
                    textField: {
                      placeholder: reportLabels.toDatePlaceholder,
                      className: "form-control requiredDate",
                    },
                  }}
                  onChange={(date) => field.onChange(date)}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="ByDateTo"
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ReportDateRangePicker;